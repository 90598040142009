import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from '@/app.settings.theme' 
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
Vue.config.productionTip = false;
import esriConfig from "@arcgis/core/config.js";
esriConfig.assetsPath = "./assets";

import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFac1dJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNiUH5fcnxWRWNeWUc=');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
