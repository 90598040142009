<template>
  <div style="background-color: #eaf2f8">
    <!-- #EAF2F8 -->
    <v-btn
      absolute
      fab
      bottom
      right
      class="accent black--text"
      @click="
        showDialog = true;
        this.connection = this.emptyConnection;
      "
      style="position: fixed; bottom: 25px; right: 25px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-toolbar flat dense elevation="1">
      <v-toolbar-title>My Databases</v-toolbar-title>
      <v-btn icon @click="showDialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-col class="mb-n6 col-lg-2 col-xs-6" v-show="show_search">
        <v-text-field
          v-model="search"
          placeholder="search"
          dense
          required
        ></v-text-field>
      </v-col>
      <v-btn icon @click="show_search = !show_search">
        <v-icon :color="searchStatusColor">mdi-magnify</v-icon>
      </v-btn>
      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-row no-gutters>
      <v-col
        v-for="(item, i) in filteredConnections"
        :key="i"
        col-md-6
        col-xs-12
      >
        <v-card class="ma-1" tile>
          <v-list-item @click="GetTables(item)">
            <v-list-item-avatar tile large>
              <img
                v-if="item.dbType == 'mssql'"
                width="18"
                aspect-ratio="1"
                src="../../public/img/icons/mssql.png"
              />
              <img
                v-else-if="item.dbType == 'mysql'"
                width="18"
                aspect-ratio="1"
                src="../../public/img/icons/mysql.png"
              />
              <img
                v-else-if="item.dbType == 'pgsql'"
                width="20"
                aspect-ratio="1"
                src="../../public/img/icons/pgsql.png"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }} |
                <small color="blue">Type:</small>
                {{ item.dbType }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon x-small>dns</v-icon>
                {{ item.server }} |
                <v-icon x-small>mdi-database</v-icon>
                {{ item.database }} |
                <v-icon x-small>mdi-table</v-icon>
                {{ item.db.noOfTables }}
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon
                        small
                        @click="TestConnection()"
                        :color="item.status | getIconColor"
                        >{{ item.status }}</v-icon
                      >
                    </v-btn>
                  </template>
                  <small v-if="item.status == 'error'"
                    >connection to the specified server failed</small
                  >
                </v-tooltip>
                <v-btn icon>
                  <v-icon small @click="TestConnection()">mdi-refresh</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>mdi-plus</v-icon>
                    <v-list-item-title>Create Database</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>mdi-link</v-icon>
                    <v-list-item-title>Rename Connection</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeConnection(i)">
                    <v-icon class="pa-1" small>mdi-cancel</v-icon>
                    <v-list-item-title>Delete Connection</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <div
          class="text-center primary"
          style="padding: 10px; magin-bottom: 10px"
        >
          <img
            width="60"
            aspect-ratio="1"
            src="../../public/img/icons/add_database_100px.png"
          />
          <h3 class="text-center">Database Connection</h3>
          <small class="text-center"
            >Test using the database connection below or enter your own.</small
          >
        </div>
        <v-card-text style="height: 300px; overflow: auto; padding-top: 20px">
          <v-row>
            <v-col col-md-6>
              <v-select
                v-on="setPort()"
                v-model="connection.dbType"
                :items="dbType"
                label="Select database type*"
                required
                dense
              ></v-select>
            </v-col>
            <v-col col-md-6>
              <v-text-field
                v-model="connection.description"
                prepend-inner-icon="mdi-app"
                dense
                label="Name your connection*"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-6>
              <v-text-field
                v-model="connection.server"
                label="Server*"
                dense
                prepend-inner-icon="dns"
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-6>
              <v-text-field
                v-model="connection.port"
                label="Port Number"
                prepend-inner-icon="port"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-6>
              <v-text-field
                v-model="connection.userId"
                prepend-inner-icon="mdi-email"
                dense
                label="User Id*"
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-6>
              <v-text-field
                type="password"
                v-model="connection.password"
                dense
                prepend-inner-icon="mdi-pass"
                label="Password*"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-6>
              <v-select
                v-model="connection.database"
                :items="databases"
                label="Select a database"
                prepend-inner-icon="view_stream"
                required
                hint="Test connection to load databases"
                persistent-hint
                dense
              ></v-select>
            </v-col>
            <v-col col-md-6>
              <v-text-field
                dense
                prepend-inner-icon="mdi-database"
                v-model="connection.database"
                label="Database*"
                required
              ></v-text-field> </v-col
          ></v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="warn" tile @click="TestConnection()"
            >Test Connection</v-btn
          >
          <v-btn text class="primary" tile @click="SaveConnection()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}</v-snackbar
    >
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "../app.settings.network";
import router from "../router";
import AppSettings from "../app.settings";
const apiUrl = AppSettings.API_ENDPOINT;
export default {
  data: function () {
    return {
      search: "",
      searchStatusColor: "primary",
      show_search: false,
      connections: [],
      databases: [],
      connection: {
        server: "143.95.251.10",
        userId: "cloudapp",
        password: "",
        database: "",
        dbType: "mssql",
        port: "1433",
        description: "database description",
        status: "",
        time: null,
        db: { noOfTables: 0, noOfViews: 0 },
      },
      emptyConnection: {
        server: "",
        userId: "",
        password: "",
        database: "",
        dbType: "",
        port: "",
        description: "",
        status: "",
        time: null,
        db: { noOfTables: 0, noOfViews: 0 },
      },
      vendors: ["mssql", "mysql", "pgsql", "oracle"],
      dbType: [
        { text: "mssql", port: 1433 },
        { text: "mysql", port: 3306 },
        { text: "pgsql", port: 5432 },
        { text: "oracle", port: 1521 },
      ],
      showDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
    };
  },
  filters: {
    getIcon: function (value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  computed: {
    filteredConnections() {
      if (this.connections) {
        if (this.search !== "") {
          const search = this.search.toLowerCase();
          return this.connections.filter(
            (item) =>
              item.description.toLowerCase().includes(search) ||
              item.database.toLowerCase().includes(search) ||
              item.server.toLowerCase().includes(search) ||
              item.dbType.toLowerCase().includes(search)
          );
        }
        return this.connections;
      } else {
        return [];
      }
    },
  },
  methods: {
    setPort() {
      if (this.connection.dbType == "mssql") {
        this.connection.port = 1433;
      } else if (this.connection.dbType == "mysql") {
        this.connection.port = 3306;
      } else if (this.connection.dbType == "pgsql") {
        this.connection.port = 5432;
      } else if (this.connection.dbType == "oracle") {
        this.connection.port = 1521;
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
    removeConnection(i) {
      this.connections.splice(i, 1);
      const connections = this.connections;
      localStorage.setItem(
        "connections",
        this.encriptPass(JSON.stringify(connections))
      );
    },
    GetTables(connection) {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .post("api/database/tables", connection)
          .then((response) => {
            //console.log(response.data)
            var today = new Date();
            var time =
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();
            connection.time = time;
            this.$store.commit("setTables", response.data.tables);
            this.$store.commit("setConnection", connection);
            this.showProgress = false;
            //this.tables = response.data;
            console.log(response.data)
            this.showDialog = false;
            this.alert.bgcolor = "primary";
            this.alert.snackbar = true;
            this.alert.text = "Database tables loaded successfully";
            if (this.$route.name == "Database") {
              router.push("Tables");
            } else if (this.$route.name == "SSRecord") {
            }
          })
          .catch(function (ex) {
            vm.showProgress = false;
            if (ex.response !== undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        return (
          (vm.showProgress = false),
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    TestConnection() {
      this.showProgress = true;
      const vm = this;
      try {
        this.connection.database = null;
        Api()
          .post(apiUrl + "api/databases", this.connection)
          .then((response) => {
            const databases = response.data.databases;
            this.databases = databases;
            this.connection.database = databases[0].test;
            return (
              (this.showProgress = false),
              (this.alert.snackbar = true),
              (this.alert.bgcolor = "primary"),
              (this.alert.text = "test connection successful")
            );
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          })
          .catch(function (ex) {
            if (ex.response !== undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"), //ex.message
                (vm.alert.text =
                  "The server may not be available at the moment or your internat connection is down")
              );
            }
          });
      } catch (e) {
        return (
          (vm.showDialog = false),
          (vm.alert.alertDialog = true),
          ((vm.alert.bgcolor = "red"),
          (vm.alert.text =
            "The server may not be available at the moment or your internat connection is down")) //e.message
        );
      }
    },
    renameConnection(item, index, newName) {
      item.description = newName;
      this.connections[index] = item;
      localStorage.setItem(
        "connections",
        this.encriptPass(JSON.stringify(this.connections))
      );
    },
    SaveConnection() {
      this.showProgress = true;
      const vm = this;
      try {
        var connections = [];
        Api()
          .post(apiUrl + "api/database/testconnection", this.connection)
          .then((response) => {
            let dbcon = this.connection;
            dbcon.db.noOfTables = response.data.noOfTables;

            if (this.connections == null) {
              dbcon.id = 1;
              connections[0] = dbcon;
            } else {
              connections = this.connections;
              dbcon.id = connections.length + 1;
              connections.push(dbcon);
            }
            //alert(JSON.stringify(response.data));
            localStorage.setItem(
              "connections",
              this.encriptPass(JSON.stringify(connections))
            );

            return (
              (this.showProgress = false),
              (this.connections = connections),
              (this.showDialog = false),
              (this.alert.snackbar = true),
              (this.alert.bgcolor = "green"),
              (this.alert.text = "Connection established successfully"),
              (this.connection = this.emptyConnection)
            );
          })
          .catch(function (ex) {
            var msg = "";
            try {
              msg = ex.response.data.message;
            } catch (e) {
              msg = JSON.stringify(ex.message);
            }
            return (
              (vm.showProgress = false),
              (vm.showDialog = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = msg)
            );
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    changeData() {
      this.message = "The Spirit of God is Excellent";
    },
  },
  created() {
    const connections = JSON.parse(
      this.decriptPass(localStorage.getItem("connections"))
    );
    return (this.connections = connections);
  },
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>
