<template>
  <div id="app">
    <v-app id="vapp" style="background-color: #eaf2f8">
      <!-- <div > -->
      <v-app-bar
        v-if="isLoggedIn"
        app
        flat
        clipped-left
        collapse-on-scroll
        color="white"
        ref="topbar"
        elevation="1"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <img width="35" aspect-ratio="1" src="./assets/logo.png" />
        <v-toolbar-title class="ml-0 pl-3">
          <span class="hidden-xs-and-down">Flexiblebase</span>
        </v-toolbar-title>
        <!-- <v-breadcrumbs :items="items" class="dense hidden-xs-only"></v-breadcrumbs> -->

        <v-spacer></v-spacer>
        <v-btn
          v-show="isLoggedIn"
          class="hidden-xs-only hidden-sm-only"
          text
          style="
            text-transform: lowercase;
            display: inline-block;
            font-family: sans-serif;
          "
        >
          <v-icon>person</v-icon>{{ userName }}
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-if="isLoggedIn"
        dark
        clipped
        app
        mini-variant
        mini-variant-width="75"
        class="primary darken-2"
        expand-on-hover
        v-model="drawer"
        max-width="400"
        width="350"
        elevation="5"
      >
        <div
          class="text-center white hidden-lg-only pt-4"
          max-width="375"
          max-height="250"
          elevation="0"
          style="padding: 3px"
        >
          <img src="./assets/logo_with_text.png" width="250" alt="" />
        </div>
        <v-divider />
        <v-list class="subtitle font-weight-light" two-line>
          <v-list-item
            style="height: 35px"
            class="bottom-line font-weight-light ml-1"
          >
            <!-- <v-list-item-icon>
             <v-icon>mdi-server</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-subtitle class="white--text">
                <v-icon color="white" small>mdi-server</v-icon>
                {{ connection.server }} <br />
                <v-icon color="white" small>mdi-database</v-icon>
                {{ connection.database }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <v-list class="ma-2">
          <v-list-item
            @click="SignOut(item)"
            class="bottom-line"
            v-for="item in items"
            :key="item.title">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-list>
          <!-- <v-list-group v-show="item.items" 
            class="bottom-line-white"
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item 
              v-for="subItem in item.items"
              :key="subItem.title"
              :to="subItem.component"
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> -->
          <v-list-item
            class="bottom-line"
            v-for="item in items"
            :to="item.component"
            :key="item.title"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="font-weight-light">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle> {{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="SignOut()" class="bottom-line">
            <v-list-item-icon>
              <v-icon>power_settings_new</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
              <v-list-item-subTitle>{{ userName }}</v-list-item-subTitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- style="background-color:#EAF2F8" -->
      <v-main>
        <router-view></router-view>
        <!-- </v-container> -->
      </v-main>
      <!-- <v-btn fab bottom right color="red" dark fixed @click.stop="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>-->
      <!-- <bottom-bar v-show="isLoggedIn" class="hidden-xs-only" /> -->

      <!-- </div> -->
      <!-- <div v-else>
      <Login/>
      </div>-->
    </v-app>
  </div>
</template>
<script>
//  @ is an alias to /src
// import TopBar from '@/components/TopBar.vue'
//import BottomBar from "@/components/Footer.vue";
//import Login from "./views/Login";
//import router from "./router";
// import SideBar from '@/components/SideBar.vue'
//  import AppBar from '@/components/AppBar.vue'

export default {
  name: "Home",
  components: {
    // AppBar,
    // TopBar,
    //BottomBar,
    // Login
    // SideBar
  },
  props: {
    source: String,
  },
  data() {
    return {
      colors: ["primary", "blue", "success", "red", "teal"],
      permanent: true,
      miniVariant: true,
      expandOnHover: true,
      background: false,
      dialog: false,
      color: "blue",
      drawer: false,
      left: false,
      items: [
        {
          title: "Database",
          icon: "mdi-home",
          component: "/",
          href: "Database",
          text: "Connect and manage databases",
          disabled: false,
        },
        {
          title: "Tables, Views & Queries",
          icon: "mdi-table",
          component: "tables",
          href: "/tables",
          text: "Display records and perform CRUD operations",
          disabled: false,
        },
        {
          title: "Tables Design",
          icon: "draw",
          component: "Design",
          href: "/Design",
          text: "Design table schema and create table.",
          disabled: false,
        },
        {
          title: "SQL Interface",
          icon: "join_inner",
          component: "Query",
          href: "/Query",
          text: "Compose SQL query like a pro",
          disabled: false,
        },
        {
          title: "Teams and Sharing",
          icon: "supervisor_account",
          component: "team",
          href: "/team",
          text: "Create and manage team members",
          disabled: false,
        },
        {
          title: "Backend Api",
          icon: "api",
          component: "api",
          href: "/api",
          text: "Enable backend api for your database",
          disabled: false,
        },
        {
          title: "Frontend UI",
          icon: "code",
          component: "developer",
          href: "/developer",
          text: "Generate forms for your html apps",
          disabled: false,
        },
        {
          title: "Maps",
          icon: "location_on",
          component: "map",
          href: "/map",
          text: "View your spatial data on map",
          disabled: false,
        },
        { title: "Settings", component: "Settings", icon: "settings", text: "Settings" },
        {
          title: "Feedback",
          icon: "forum",
          component: "feedback",
          text: "Report a bug or send us a feedback",
          disabled: false,
        },
        { title: "Help", icon: "help", text: "Help" },
        // {
        //   title: "Sign Out",
        //   icon: "power_settings_new",
        //   component: "Login",
        // },
      ],
    };
  },
  computed: {
    connection: function () {
      return this.$store.getters.getConnection;
    },
    userName: function () {
      return this.$store.getters.getUserName;
    },
    isConnected: function () {
      const conn = this.$store.getters.getConnection;
      if (conn.database == undefined || conn.database == null) {
        return false;
      }
      return true;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    SignOut: function () {
      this.$store.dispatch("logout");
    },
    load(item) {
      if (item.title == "Sign Out") {
        this.$store.commit("logout");
      }
      alert("out");
      return "go";
    },
  },
  mounted: function () {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("share") !== null) {
      const a = urlParams.get("share").toString();
      const b = decodeURIComponent(a);
      //console.log(b);
      localStorage.setItem("receivedConnections", b);
      //alert(b);
    }
  },
  created() {
    //router.replace('Login')
    this.$store.dispatch("tryAutoLogin");
  },
};
</script>

<style lang="scss">
// @import 'https://js.arcgis.com/4.23/@arcgis/core/assets/esri/themes/light/main.css';
@import "https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css";
//@import url("app.css");
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.map-div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.navigation-drawer__border {
  //width: 300px!important;
  //background-color: red!important;
  border-style: none;
  border-block-color: white;
}
.centre {
  text-align: center;
}
.bottom-line {
  border-bottom: solid thin rgb(226, 226, 226);
}
.bottom-line-white {
  border-bottom: solid thin rgb(255, 255, 255);
}
@import "@arcgis/core/assets/esri/themes/light/main.css";
</style>
