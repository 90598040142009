<template>
  <div class="transparent">
    <v-row no-gutters dense>
      <v-col
        col-md-6
        col-xs-12
        :key="i"
        v-for="(item, i) in initSqlQueries">
        <v-card tile class="ma-1" elevation="1">
          <v-list-item @click="pushSelectedQuery(item)">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }}
                <small
                  ><v-icon x-small>schedule</v-icon>{{ " " + item.timeSince }}
                </small>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.statement }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>mdi-plus</v-icon>
                    <v-list-item-title>Create Database</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="removeConnection(i)">
                    <v-icon class="pa-1" small>mdi-delete</v-icon>
                    <v-list-item-title>Delete Query</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}
    </v-snackbar>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import router from '../router';
export default {
  data: function () {
    return {
      initSqlQueries: {},
      search: "",
      searchStatusColor: "primary",
      show_search: false,
      sqlQuery: {
        description: "",
        database: "",
        statement: "",
        date: null,
      },
      showDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
    };
  },
  filters: {
    getIcon: function (value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  computed: {
    filteredConnections() {
      if (this.sqlQueries) {
        if (this.search !== "") {
          return this.sqlQueries.filter(
            (item) =>
              item.description.toLowerCase().includes(this.search) ||
              item.database.toLowerCase().includes(this.search) ||
              item.server.toLowerCase().includes(this.search) ||
              item.dbType.toLowerCase().includes(this.search)
          );
        }
        return this.sqlQueries;
      } else {
        return [];
      }
    },
    sqlQueries() {
      const req = this.$store.getters.getSqlQueries;
      //alert(req.length)
      return req;
    },
  },
  watch: {
    // search:{function(search) {
    //   const filteredTables = this.tables.filter(item => item.tableName.toLowerCase().includes(search));
    //   return this.filteredTables = filteredTables;
    // }},
    sqlQueries(req) {
      return (this.initSqlQueries = req);
    },
  },
  methods: {
    pushSelectedQuery(item) {
      this.$store.commit("setSelectedQuery", item);

      if (this.$router.currentRoute.name == "Tables") 
      {
        router.push('/query')
      }
      
      // if (this.$router.currentRoute.name !== "SSRecords" ||
      // this.$router.currentRoute.name !== "CSRecords") 
      // {
      //    return
      // } else {
      //      router.push('/query')
      // }     
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
    removeConnection(i) {
      this.sqlQueries.splice(i, 1);
      const sqlQueries = this.sqlQueries;
      localStorage.setItem(
        "sqlQueries",
        this.encriptPass(JSON.stringify(sqlQueries))
      );
    },
  },
  created() {
    this.initSqlQueries = this.$store.getters.getSqlQueries;
  },
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>
