<template>
  <div style="background-color: #eaf2f8">
    <v-row no-gutters v-if="filteredTables.length > 0" class="py-1">
      <v-col v-for="(item, i) in filteredTables" :key="i" col-md-6 col-xs-12>
        <v-card class="mb-1 mx-1" tile flat elevation="1">
          <v-list-item :key="item.tableName" @click="getTableRecords(item)">
            <v-list-item-icon>   
              <v-icon color="primary">mdi-table</v-icon>          
            </v-list-item-icon>
            <!-- <v-list-item-content> -->
              <v-list-item-title>                 
                {{ item.tableName }} 
                <v-icon x-small color="grey">table_rows</v-icon>
               <small >rows:</small>
                {{ item.rowsCount }},
                <v-icon small color="grey">view_column</v-icon>
                <small color="primary">cols:</small>
                {{ item.columns.length }}           
              </v-list-item-title>
                 <!-- <v-list-item-subtitle>
                  <v-icon x-small>table_rows</v-icon>
               <small color="blue">rows:</small>
                {{ item.rowsCount }},
                <v-icon x-small>view_column</v-icon>
                <small color="blue">cols:</small>
                {{ item.columns.length }}    

              </v-list-item-subtitle> -->
            <!-- </v-list-item-content> -->
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showTableSettings(item)">
                    <v-icon class="pa-1" small>fa-list</v-icon>
                    <v-list-item-title>Columns</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="getTableRecords(item)">
                    <v-icon class="pa-1" small>fa-list</v-icon>
                    <v-list-item-title>Select Top 100</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>fa-list</v-icon>
                    <v-list-item-title>Select Top 1000</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>fa-list</v-icon>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>fa-edit</v-icon>
                    <v-list-item-title>Rename Table</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>fa-trash</v-icon>
                    <v-list-item-title>Delete Table</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class>mdi-file</v-icon>
                    <v-list-item-title>Script Create Table</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="alert('Do something here')">
                    <v-icon class="pa-1" small>fa-arrow-circle-right</v-icon>
                    <v-list-item-title>Export Table To JSON</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <p class="ma-3, pa-3">
        There are no tables to display, please modify your search.
      </p>
    </div>

    <v-dialog v-model="tableSettingsDialog" max-width="350px">
      <v-card>
        <div class="dark" style="padding: 10px; magin-bottom: 10px">
          <h3>
            <v-icon color="primary">mdi-table</v-icon>
            {{ selectedTable.tableName }} fields
          </h3>
          <small class="text-center"
            >Select fields and click OK to load records</small
          >
        </div>
        <!-- <v-list style="height:300px;overflow:auto;">
          <v-list-item-group v-model="selectedColumns" multiple active-class>
            <v-list-item>
              <template v-slot:default="{ active }" v-for="(col, i) in colSettingsData.columns">
                <v-divider :key="`divider-${i}`"></v-divider>
                <v-list-item-action :key="i">
                  <v-checkbox :input-value="active" :true-value="item" @click="toggle" color="primary"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title :key="i">{{col}}</v-list-item-title>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>-->
        <v-list style="height: 300px; overflow: auto">
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                :indeterminate="someColumnsSelected"
                :input-value="allColumnsSelected"
                @click.stop="toggleCompleteSelection"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >Select All</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group v-model="selectedColumns" multiple>
            <template v-for="(item, k) in selectedTable.columns" >
              <v-list-item :value="item" :key="k">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      :true-value="item"
                      @click="toggle"
                    ></v-checkbox>
                    <!-- <v-btn icon> -->
                    <!-- <v-icon @click="TestConnection()">mdi-pencil</v-icon> -->
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item"> </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action inline>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small color="grey" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="alert('Do something here')">
                          <v-icon class="pa-1" small>fa-edit</v-icon>
                          <v-list-item-title>Rename</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="alert('Do something here')">
                          <v-icon class="pa-1" small>fa-trash</v-icon>
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="alert('Do something here')">
                          <v-icon class>mdi-file</v-icon>
                          <v-list-item-title
                            >Set as Primary key</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider :key="item"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <!-- <v-divider></v-divider> -->
        <v-card-actions color="white">
          <v-btn color="danger" text @click="tableSettingsDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="saveColumns()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          Connecting to your database, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.color"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}</v-snackbar
    >
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "../app.settings.network";
import router from "../router";
import AppSettings from "../app.settings";
const apiUrl = AppSettings.API_ENDPOINT;

export default {
  props: ["search"],
  data: function () {
    return {
      selectedNodeIds: [],
      graphWithNodes: {
        id: 1,
        name: "The graph",
        nodes: [
          {
            id: 1,
            graphId: 1,
          },
          {
            id: 2,
            graphId: 1,
          },
        ],
      },
      showColumnChoser: false,
      selectedTable: { columns: [], selectedColumns: [], tableName: "" },
      selectedColumns: [],
      selected: "",
      dbType: ["mssql", "mysql", "pgsql", "sqllite"],
      tableSettingsDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
      activeTable: {},
    };
  },
  filters: {
    getIcon: function (value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor: function (value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
    getColumns: function (value) {
      try {
        var col = [];
        col = "[" + JSON.parse(value) + "]";
        alert(col);
        return col;
      } catch (ex) {
        alert(ex.message);
      }
    },
  },
  computed: {
    progress_status() {
      return this.$store.getters.getProgressStatus;
    },
    filteredTables() {
      if (this.search !== "") {
        return this.tables.filter((item) =>
          item.tableName.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.tables;
    },
    tables: {
      get: function () {
        let req = this.$store.getters.getTables;
        //alert(JSON.stringify(req));
        try {
          req.forEach(function (item, index) {
            let selCol = req[index].columns;
            var col = '["' + selCol.split(",").join('","') + '"]';
            req[index].columns = JSON.parse(col);
          });
          //alert(JSON.stringify(req));
          return req;
        } catch (ex) {
          return req;
        }
      },
      set: function (value) {
        alert(JSON.stringify(value));
        //return (this.tables = value);
      },
    },
    connection: function () {
      return this.$store.getters.getConnection;
    },
    noColumnsSelected() {
      return this.selectedColumns.length === 0;
    },
    someColumnsSelected() {
      return this.selectedColumns.length > 0 && !this.allColumnsSelected;
    },
    allColumnsSelected() {
      return this.selectedColumns.length === this.selectedTable.columns.length;
    },
  },
  methods: {
    toggleCompleteSelection() {
      if (this.noColumnsSelected || this.someColumnsSelected) {
        this.selectedColumns = this.selectedTable.columns.map((node) => node);
      } else {
        this.selectedColumns = [];
      }
    },
    saveColumns() {
      let columns = this.selectedColumns;
      let tableName = this.selectedTable.tableName;
      this.activeTable = { tableName: tableName, columns: columns };
      this.getTableRecords(this.activeTable);
      this.tableSettingsDialog = false;
    },
    showTableSettings(item) {
      this.tableSettingsDialog = true;
      this.selectedTable = item;
      //alert("active table :" + JSON.stringify( this.activeTable));
    },
    getTableRecords(item) {
      //this.showProgress = true;
      //const vm = this;
      this.connection.columns = this.selectedColumns;
      this.connection.tableName = item.tableName;
      this.$store.commit("setConnection", this.connection);
      if (this.$route.name == "Tables") {
            router.push("SSRecords");
      }
      // try {
      //   let payload = {
      //     tableName: item.tableName,
      //     connection: this.connection
      //   };
      //   Api()
      //     .post(apiUrl + "api/table", payload)
      //     .then((response) => {           
      //       this.$store.commit("setTable", response.data);
      //       if (this.$route.name !== "SSRecord") {
      //         router.push("SSRecord");
      //       }
      //       return (this.showProgress = false), (this.showDialog = false);
      //     })
      //     .catch(function (ex) {
      //       if (ex.response != undefined) {
      //         return (
      //           (vm.showProgress = false),
      //           (vm.showDialog = false),
      //           (vm.alert.alertDialog = true),
      //           (vm.alert.bgcolor = "red"),
      //           (vm.alert.text = ex.response.data.message)
      //         );
      //       } else {
      //         return (
      //           (vm.showProgress = false),
      //           (vm.showDialog = false),
      //           (vm.alert.alertDialog = true),
      //           (vm.alert.bgcolor = "red"),
      //           (vm.alert.text = ex.message)
      //         );
      //       }
      //     });
      // } catch (e) {
      //   //alert(JSON.stringify(e))
      //   return (
      //     (this.showDialog = false),
      //     (this.alert.alertDialog = true),
      //     (this.alert.bgcolor = "red"),
      //     (this.alert.text = e.message),
      //     (this.alert.title = "Table Records")
      //   );
      // }
    },
    GetTables() {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .post(apiUrl + "api/database/tables", this.connection)
          .then((response) => {
            return (
              (this.showProgress = false),
              (this.tables = response.data),
              (this.showDialog = false),
              (this.alert.snackbar = true),
              (this.alert.text = response.data.message)
            );
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    changeData() {
      this.message = "The Spirit of God is Exellent";
    },
  },
  watch: {
    tables2: {
      handler: function (req) {
        //alert(req);
        req.forEach(function (item, index) {
          let selCol = req[index].columns;
          var col = '["' + selCol.split(",").join('","') + '"]';
          req[index].columns = JSON.parse(col);
        });
        return (this.tables2 = req);
      },
      deep: true,
    },
  },
  //watch:{ myComputedProperty : { handler(newVal){ do something when change happens}, deep: true}}

  created() {},
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>