/* eslint-disable prettier/prettier */
<template>
  <v-sheet class="ma-1">
  <v-row no-gutters>
    <v-col key="tablePanel1" cols="12" sm="3" v-if="tablePanel">
      <v-card class="mr-1 transparent" elevation="0" tile>
        <v-tabs v-model="tab" fixed-tabs class="transparent">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab>Database</v-tab>
          <v-tab>Tables</v-tab>
          <v-tab>Views</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pr-n3">
          <v-tab-item style="height: 83vh; overflow: auto">
            <data-base></data-base>
          </v-tab-item>
          <v-tab-item style="height: 83vh; overflow: auto">
            <table-list :search="search"></table-list>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text> View </v-card-text>
            </v-card>
          </v-tab-item>
        
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-col cols="12" :sm="rightPanel">
      <v-toolbar elevation="0" flat dense>
        <!-- <v-btn icon @click="tablePanel = !tablePanel">
            <v-icon>mdi-menu</v-icon>
          </v-btn> -->
        <v-btn text icon @click="showTablePanel">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-icon>mdi-database</v-icon>
        {{ connection.database }}/ <v-icon>mdi-table</v-icon>
        <v-badge
          :content="table.data.count ? table.data.count : 0"
          :value="table.data.count ? table.data.count : 0"
          color="red"
          >{{ tableName }}
        </v-badge>
        <v-btn icon @click="getTableRecords()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn text icon @click="showSqlPanel">
          <v-icon>mdi-file-code</v-icon>
        </v-btn>
        <v-btn text icon @click="showImportCsvDialog = true">
          <v-icon>mdi-file-delimited-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-switch
          dense
          class="mt-2"
          v-model="multipleSelection"
          label="Multiple"
        ></v-switch>
        <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
      </v-toolbar>
      <div>
        <!-- <ejs-tooltip
          style="background-color: white"
          ref="tooltip"
          target=".e-rowcell"
          :cssClass="toolTipCssClass"
          :beforeRender="beforeRender"
        > -->
          <ejs-grid
            :load="tooltipContent"
            style="border-style: none"
            width="100%"
            ref="grid"
            :excelExportComplete="excelExportComplete"
            :pdfExportComplete="pdfExportComplete"
            :actionFailure="actionFailure"
            :dataStateChange="dataStateChange"
            :dataSourceChanged="actionBegin"
            :columns="table.clientColumns"
            :dataSource="table.data"
            :dataBound="dataBound"
            :rowHeight="35"
            :allowPaging="true"
            :allowSorting="true"
            :pageSettings="pageSettings"
            :selectionSettings="selectionSettings"
            :allowFiltering="true"
            :filterSettings="filterOptions"
            :actionComplete="actionComplete"
            :allowSelection="true"
            :contextMenuItems="contextMenuItems"
            :editSettings="editSettings"
            :showColumnMenu="true"
            :toolbar="toolbar"
            :showColumnChooser="true"
            :allowGrouping="false"
            :allowExcelExport="true"
            :allowPdfExport="true"
            :allowReordering="true"
            :allowResizing="true"
            :rowSelected="onRowSelected"
            :toolbarClick="toolbarClick"
          >
            <e-columns>
              <!-- <e-column
            type="checkbox"
            :allowFiltering="false"
            :allowSorting="false"
            :allowEditing="false"
            width="40"
          ></e-column>
          <e-column headerText="Commands" :allowEditing="false" width="70" :commands="commands"></e-column>-->
              <!-- <e-column
                v-for="item in columns"
                :key="item.field"
                :headerText="item.headerText"
                :width="item.width"
                :field="item.field"
                :textAlign="item.textAlign"
                :type="item.type"
                :allowEditing="item.allowEditing"
                :isPrimaryKey="item.isPrimaryKey"
          ></e-column>-->
            </e-columns>
          </ejs-grid>
        <!-- </ejs-tooltip> -->
      </div>
      <v-dialog v-model="detailDialog" max-width="800px" scrollable>
        <v-card max-width="100%">
          <v-card-title>
            Details <v-spacer></v-spacer>
            <v-btn text class="primary--text" @click="detailDialog = false">
              <v-icon>cancel</v-icon>Cancel
            </v-btn>
            <v-btn text class="primary--text">
              <v-icon>done</v-icon>Save
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="black--text" style="height: 400px">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                v-for="(item, value) in selection"
                :key="value"
              >
                <v-sheet v-if="checkBase64(selection[value])">
                  <embed
                    width="100%"
                    height="auto"
                    v-if="checkIfPDF(selection[value])"
                    :src="'data:application/pdf;base64,' + selection[value]"
                    :key="value"
                  />
                  <v-img
                    width="100%"
                    v-else
                    :src="getMimeType(selection[value])"
                  />
                </v-sheet>
                <v-text-field
                  hide-details="true"
                  v-else
                  type="text"
                  readonly
                  v-model="selection[value]"
                  :label="value"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveEdits(selection)"
            >Save</v-btn
          >
        </v-card-actions> -->
        </v-card>
      </v-dialog>

      <v-dialog v-model="showImportCsvDialog" max-width="900">
        <v-card max-width="100%">
          <v-card-title>
            Import CSV <v-spacer></v-spacer>
            <!-- <v-file-input
            label="CSV comma delimited file"
            v-model="csvFile"
            show-size
            accept="file/csv,"
            dense
            @change="readCSV"
          ></v-file-input> -->
            <v-btn text class="warn--text" @click="showImportCsvDialog = false">
              <v-icon>cancel</v-icon>Close
            </v-btn>
            <v-btn text class="danger--text" @click="clearDropZoneContent()">
              <v-icon>delete</v-icon>Clear
            </v-btn>
            <v-btn text class="primary--text" @click="insertBulk()">
              <v-icon>done</v-icon>Save
            </v-btn>
          </v-card-title>

          <v-card-text>
            <vue-dropzone
              ref="myVueDropzone"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              v-on:vdropzone-file-added="readCSV"
              v-if="!csvJson"
              style="height: 300px"
            >
              <div class="dropzone-custom-content mt-16">
                <v-avatar tile size="100">
                  <v-icon x-large size="100" color="primary"
                    >mdi-file-upload</v-icon
                  >
                </v-avatar>

                <h3 class="primary--text">Drag and drop CSV file to upload!</h3>
                <div class="subtitle">
                  ...or click to select a file from your computer
                </div>
              </div>
            </vue-dropzone>
            <ejs-grid
              v-else
              height="300px"
              style="border-style: none"
              width="100%"
              ref="gridImport"
              gridLines="horizontal"
              :dataSource="csvJson"
              :dataBound="dataBoundImport"
              :rowHeight="30"
              :allowPaging="true"
              :allowSorting="true"
              :pageSettings="importPageSettings"
              :allowSelection="true"
              :showColumnMenu="true"
              :allowReordering="true"
              :allowResizing="true"
              :resizeSettings="resizeSettings"
            >
              <e-columns> </e-columns>
            </ejs-grid>
          </v-card-text>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>         
         
        </v-card-actions> -->
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" max-width="550px">
        <v-toolbar dense elevation="0">
          <v-toolbar-title>
            Edit Records
            <v-icon color="red">mdi-arrow-right</v-icon>
            <v-icon x-small>mdi-file</v-icon>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text> <v-icon>cancel</v-icon>Cancel </v-btn>
          <v-btn text> <v-icon>done</v-icon>Save </v-btn>
        </v-toolbar>
        <v-card>
          <!--<div class="text-center blue darken-2 dark white--text pa-3" elevation="1">
          <h3>
            <v-icon x-small color="white">mdi-database</v-icon>
            {{connection.database}} /
            <v-icon x-small color="white">mdi-table</v-icon>
            {{ table.tableName }}
            <small>
              (
              <v-icon x-small color="white">mdi-key</v-icon>
              Primary Key = {{ table.primaryKey }})
            </small>
          </h3>
          <h4></h4>
          <small>supply the fields values and click save</small>
        </div>-->
          <v-card-text
            class="black--text"
            style="height: 300px; overflow: auto"
          >
            <v-container>
              <v-text-field
                v-for="(item, value) in selection"
                type="text"
                :key="value"
                :v-model="selection"
                :label="value"
              >
                <v-icon slot="prepend" color small>mdi-pencil</v-icon>
              </v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveEdits(selection)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showProgress"
        hide-overlay
        persistent
        class="pa-3"
        width="400"
      >
        <v-card color="white">
          <v-card-text>
            processing your request, please wait...
            <v-progress-linear
              indeterminate
              color="primary"
              class="my-3"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alert.alertDialog" max-width="290">
        <v-card>
          <div :class="alert.bgcolor">
            <v-card-title class="title white--text">
              <v-icon v-if="alert.bgcolor == 'failure'" color="white"
                >cancel</v-icon
              >
              <v-icon v-else color="white">done</v-icon>
              {{ alert.title }}
            </v-card-title>
          </div>
          <v-card-text>
            <br />
            {{ alert.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="alert.bgcolor == 'red'"
              color="red darken-1"
              text
              @click="alert.alertDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              v-if="alert.bgcolor == 'red'"
              text
              @click="
                showDialog = true;
                alert.alertDialog = false;
              "
              >Retry</v-btn
            >
            <v-btn
              v-else
              color="primary"
              text
              @click="
                showDialog = false;
                alert.alertDialog = false;
              "
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        content-class="white--text"
        v-model="alert.snackbar"
        :bottom="alert.y === 'bottom'"
        :color="alert.bgcolor"
        :left="alert.x === 'left'"
        :right="alert.x === 'right'"
        :timeout="alert.timeout"
        :top="alert.y === 'top'"
      >
        {{ alert.text }}
      </v-snackbar>
      <v-bottom-sheet v-model="sheet">
        <v-sheet color="#eaf2f8">
          <v-toolbar dense elevation="1">
            <v-icon>mdi-database</v-icon>
            {{ connection.database }}
            <!-- <v-menu right offset-x="10">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="alert('Do something here')">
                <v-icon class="pa-1" small>mdi-list</v-icon>
                <v-list-item-title>Script Select Statement</v-list-item-title>
              </v-list-item>
              <v-list-item @click="getTableRecords(item)">
                <v-icon class="pa-1" small>mdi-list</v-icon>
                <v-list-item-title>Script Update Statement</v-list-item-title>
              </v-list-item>
              <v-list-item @click="alert('Do something here')">
                <v-icon class="pa-1" small>mdi-list</v-icon>
                <v-list-item-title>Script Insert Statement</v-list-item-title>
              </v-list-item>
              <v-list-item @click="alert('Do something here')">
                <v-icon class="pa-1" small>mdi-trash</v-icon>
                <v-list-item-title>Script Delete Statement</v-list-item-title>
              </v-list-item>
              <v-list-item @click="alert('Do something here')">
                <v-icon class>mdi-file</v-icon>
                <v-list-item-title>Script Table Definition</v-list-item-title>
              </v-list-item>
              <v-list-item @click="alert('Do something here')">
                <v-icon class="pa-1" small>mdi-arrow-circle-right</v-icon>
                <v-list-item-title>Generate JSON data</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->
            <v-spacer></v-spacer>
            <!-- <v-btn text color="primary">
            <v-icon>mdi-mark</v-icon>Execute
          </v-btn>-->
            <v-col class="mb-n6 col-lg-2 col-xs-6" v-show="show_search">
              <v-text-field
                v-model="search"
                placeholder="search"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-btn icon @click="show_search = !show_search">
              <v-icon>search</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <table-list
            :search="search"
            style="height: 300px; overflow: auto"
          ></table-list>
        </v-sheet>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="sqlsheet">
        <v-sheet height="350">
          <v-row no-gutters>
            <v-col class="col-lg-9 col-md-9 col-xs-12 col-sm-9">
              <v-toolbar dense elevation="1">
                <v-subheader> SQL PANE </v-subheader>
                <v-menu right :offset-x="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="GenerateSQLStatement('SELECT')">
                      <v-icon class="pa-1" small>mdi-select-all</v-icon>
                      <v-list-item-title
                        >Script Select Statement</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="GenerateSQLStatement('UPDATE')">
                      <v-icon class="pa-1" small>mdi-table-arrow-up</v-icon>
                      <v-list-item-title
                        >Script Update Statement</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="GenerateSQLStatement('INSERT')">
                      <v-icon class="pa-1" small
                        >mdi-table-row-plus-before</v-icon
                      >
                      <v-list-item-title
                        >Script Insert Statement</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="GenerateSQLStatement('DELETE')">
                      <v-icon class="pa-1" small>mdi-table-remove</v-icon>
                      <v-list-item-title
                        >Script Delete Statement</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="GenerateSQLStatement('STRUCTURE')">
                      <v-icon class="pa-1" small>mdi-file-table</v-icon>
                      <v-list-item-title
                        >Script Table Definition</v-list-item-title
                      >
                    </v-list-item>
                    <!-- <v-list-item @click="GenerateSQLStatement('DATA')">
                    <v-icon class>mdi-file</v-icon>
                    <v-list-item-title
                      >Script Table Data</v-list-item-title
                    >
                  </v-list-item> -->
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-col class="mb-n6 col-md-3 col-sm-6 col-xs-12">
                  <v-text-field
                    class="pa-2"
                    v-model="queryName"
                    placeholder="description"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-btn class="hidden-xs-only" text color="primary" :disabled="!isQueryNameExist" @click="executeSql()">
           Execute          
          </v-btn> -->
                <v-btn
                  icon
                  text
                  color="primary"
                  :disabled="!isQueryNameExist"
                  @click="executeSql()"
                >
                  <v-icon color="primary">done</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider />
              <editor
                v-model="code"
                @init="editorInit"
                lang="sql"
                theme="sqlserver"
                width="100%"
                height="600"
                ref="sqlText"
              ></editor>
            </v-col>
            <v-col class="col-lg-3 col-md-3 col-xs-12 col-sm-3">
              <v-toolbar dense elevation="1">
                <v-icon>history</v-icon>
                <v-subheader> Saved </v-subheader>
              </v-toolbar>
              <v-divider />
              <saved-queries
                style="height: 300px; overflow: auto"
              ></saved-queries>
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
    </v-col>
  </v-row>
</v-sheet>
</template>

<script>
import Vue from "vue";
import {
  GridPlugin,
  Filter,
  Page,
  Selection,
  Sort,
  Resize,
  ColumnMenu,
  PdfExport,
  ExcelExport,
  Toolbar,
  Reorder,
  Group,
  ColumnChooser,
  ContextMenu,
  Edit,
  CommandColumn,
  ForeignKey,
  Freeze,
} from "@syncfusion/ej2-vue-grids";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import {
  // getRecords,
  getFields,
  deleteUneditableColumns,
  generateSQLStatement,
  csvToJson,
} from "../store/services";
//import { VueCsvImportPlugin } from "vue-csv-import";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
var isBase64 = require("base64-js");
import Api from "../app.settings.network";
import AppSettings from "../app.settings";
import TableList from "../components/TablesList.vue";
import DataBase from "./Database.vue";
Vue.use(GridPlugin);
//Vue.use(VueCsvImportPlugin);

const apiUrl = AppSettings.API_ENDPOINT;
import SavedQueries from "../components/SavedQueries";
export default Vue.extend({
  components: {
    TableList,
    SavedQueries,
    vueDropzone: vue2Dropzone,
    editor: require("vue2-ace-editor"),
    TooltipPlugin,
    DataBase,
  },
  props: {},
  data: () => {
    return {
      rightPanel: 12,
      tablePanel: false,
      dropzoneOptions: {
        url: "/api/uploadImg",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        uploadMultiple: false,
        autoDiscover: false,
        maxFilesize: 10,
        autoProcessQueue: false,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
        init: function () {
          // this.on("addedfile", function (file) {
          //     return this.csvFile = file;
          // });
          this.on("error", function (file, errorMessage) {
            if (errorMessage.indexOf("Error 404") !== -1) {
              var errorDisplay = document.querySelectorAll(
                "[data-dz-errormessage]"
              );
              errorDisplay[errorDisplay.length - 1].innerHTML =
                "Error 404: The upload page was not found on the server";
            }
            if (file.accepted) {
              var mypreview = document.getElementsByClassName("dz-error");
              mypreview = mypreview[mypreview.length - 1];
              mypreview.classList.toggle("dz-error");
              mypreview.classList.toggle("dz-success");
            }
          });
        },
      },
      toolTipCssClass: "customtip",
      showImportCsvDialog: false,
      detailDialog: false,
      clientTable: { data: { result: [], count: 0 }, clientColumns: [] },
      table: { data: { result: [], count: 0 }, clientColumns: [] },
      sqlQueries: [],
      sqlQuery: {
        description: "",
        database: "",
        statement: "",
        date: null,
      },
      isQueryNameExist: false,
      queryName: "",
      search: "",
      show_search: false,
      code: "",
      coder: null,
      // Default configuration
      options: {
        mode: "sql",
        tabSize: 4,
        lineNumbers: true,
        line: true,
        autoRefresh: true,
        extraKeys: { "Ctrl-Space": "autocomplete" },
      },
      modes: [
        {
          value: "css",
          label: "CSS",
        },
        {
          value: "javascript",
          label: "Javascript",
        },
        {
          value: "html",
          label: "XML/HTML",
        },
        {
          value: "x-sh",
          label: "Shell",
        },
        {
          value: "x-sql",
          label: "SQL",
        },
      ],
      selection: {},
      sheet: false,
      sqlsheet: false,
      tab: null,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 4000,
        x: "right",
        y: "bottom",
      },
      dialog: false,
      showProgress: false,
      selRecord: {},
      height: "100%",
      allowPaging: true,
      pageSettings: {
        pageSizes: [5, 8, 10, 15, 20, 25, 50, 100, 200, 500, 1000, 2000],
        pageSize: 10,
        pageCount: 10,
        take: 100,
        skip: 0,
      },
      multipleSelection: true,
      serverPageSettings: {
        size: 500,
        skip: 0,
        pageno: 1,
      },
      filterOptions: {
        type: "Menu",
      },
      filter: {
        type: "CheckBox",
      },
      selectionSettings: {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true,
      },
      toolbar: [
        "Add",
        "Delete",
        "Update",
        "Cancel",
        "Search",
        "ColumnChooser",
        "ExcelExport",
        "PdfExport",
        "CsvExport",
      ],
      contextMenuItems: [
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        "PdfExport",
        "ExcelExport",
        "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage",
      ],
      importPageSettings: {
        pageSizes: [5, 8, 10, 15, 20, 25, 50, 100, 200, 500, 1000, 2000],
        pageSize: 100,
        pageCount: 10,
      },
      resizeSettings: { mode: "Auto" },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        mode: "Dialog", ////"Normal" 'Batch'
      },
      commands: [
        {
          type: "Edit",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
          },
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-update e-icons",
          },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      table1: {
        tableName: "",
        settings: {},
        primaryKey: "",
        rows: [],
        columns: [],
        clientColumns: [],
      },
      csvJson: null,
      tabledata1: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => (value && value.length >= 3) || "Min 3 characters",
        documentRules: (value) =>
          !value ||
          value.size < 1000000 ||
          "document size should be less than 1 MB!",
      },
    };
  },
  filters: {
    subStr: function (value) {
      if (value !== undefined) {
        return "https://adamscollege.org" + value.substring(2);
      }
    },
  },
  computed: {
    selectedQuery() {
      return this.$store.getters.getSelectedQuery;
    },
    editor() {
      // get current editor object
      return this.$refs.sqlText.editor;
    },
    connection: function () {
      return this.$store.getters.getConnection;
    },
    tables() {
      return this.$store.getters.getTables;
    },
    progressStatus() {
      return this.$store.getters.getProgressStatus;
    },
    otable() {
      let req = this.$store.getters.getTable;
      req.data = { result: req.rows, count: 100 };
      return req;
    },
    tableName() {
      const req = this.$store.getters.getTableName;
      // alert("this view component..." + req)
      return req;
    },
    settings() {
      var settings = {};
      try {
        settings = JSON.parse(localStorage.getItem("settings"));
      } catch (error) {
        settings = {
          noOfRows: 10,
          editMode: "Dialog",
          pageSize: "10",
        };
        localStorage.setItem("settings", settings);
      }
      return settings;
    },
  },
  methods: {
    editorInit: function (editor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/sqlserver");
      require("brace/mode/mysql");
      require("brace/mode/pgsql");
      require("brace/mode/sql"); //language
      require("brace/mode/less");
      require("brace/theme/sqlserver");
      require("brace/theme/clouds_midnight");
      require("brace/snippets/sqlserver"); //snippet
      //require("brace/ext/beautify");

      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        autoScrollEditorIntoView: true,
        copyWithEmptySelection: true,
        wrap: true,
        //highlightGutterLine: true,
        //animatedScroll: true,
        //showInvisibles: true,
        //showPrintMargin: true,
        //printMarginColumn: 80,
        // shortcut for showPrintMargin and printMarginColumn
         printMargin: false,
        fadeFoldWidgets: true,
        showFoldWidgets: true,
        showLineNumbers: true,
        //showGutter: true,
        //displayIndentGuides: true,
        fontSize: this.settings.sqlFontSize,
        //fontFamily: css font-family value
        // resize editor based on the contents of the editor until the number of lines reaches maxLines
        maxLines: 20,
        minLines: 5,
        // number of page sizes to scroll after document end (typical values are 0, 0.5, and 1)
        scrollPastEnd: 0.5,
        //fixedWidthGutter: false,
        //theme: "brace/theme/sqlserver",
        theme: "brace/theme/clouds_midnight",
      });
    },
    showTablePanel() {
      this.tablePanel = !this.tablePanel;
      if (this.tablePanel == false) {
        this.rightPanel = 12;
      } else {
        this.rightPanel = 9;
      }
    },
    readCSV(file) {
      try {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const text = reader.result;
          const csvJson = csvToJson(text);
          //console.log(csvJson);
          return (this.csvJson = csvJson);
        };
      } catch (e) {
        alert(e.message);
      }
    },
    clearDropZoneContent() {
      try {
        this.csvJson = null;
        // this.$refs.gridImport.dataBound();
        this.$refs.myVueDropzone.removeAllFiles();
        //showImportCsvDialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    checkBase64(value) {
      //alert(value)
      const base64 = isBase64(value);
      //alert(base64)
      return base64;
    },
    getMimeType(base64String) {
      var mediaType = "png";
      const mtype = base64String.charAt(0);
      if (mtype == "/") {
        mediaType = "image/jpg";
      } else if (mtype == "T") {
        mediaType = "image/tif";
      } else if (mtype == "i") {
        mediaType = "image/png";
      } else if (mtype == "R") {
        mediaType = "image/gif";
      } else if (mtype == "U") {
        mediaType = "image/webp";
      } else if (mtype == "J") {
        mediaType = "application/pdf";
      } else if (mtype == "P") {
        mediaType = "image/SVG";
      }
      const result = "data:" + mediaType + ";base64,";
      //alert(result)
      return result + base64String;
    },
    checkIfPDF(base64String) {
      const mtype = base64String.charAt(0);
      if (mtype == "J") {
        return true;
      } else {
        return false;
      }
    },
    beforeRender: function (args) {
      //let imgUrl = "https://flexiblebase.com/assets/images/flexiblebase-3.png";
      let mediaType = "image/png";
      if (args.target.closest("tr")) {
        this.$refs.tooltip.content = obj;
        var grid = this.$refs.grid.ej2Instances;
        var rowInfo = grid.getRowInfo(args.target).rowData;
        //alert(JSON.stringify(rowInfo.Picture));
        let data;
        var obj;
        //let obj = '<img height="150px" src="' + imgUrl + '"/>';
        var str = '<table style="color:black">';
        for (var key in rowInfo) {
          var base64 = this.checkBase64(rowInfo[key]);
          if (base64 == true) {
            mediaType = this.getMimeType(rowInfo[key]);
            //data = "data:" + mediaType + ";base64, " + rowInfo[key];
            data = mediaType;
            obj = '<embed width="100%" src="' + data + '"/>';
          } else {
            obj = '<embed height="0px"/>';
            str +=
              '<tr><td style="width:90px;text-align: left">' +
              key +
              '</td><td style="width:190px;text-align: left">' +
              rowInfo[key] +
              "</td></tr>";
          }
        }
        str += "</table>";
      }
      this.$refs.tooltip.content =
        '<div style="background-color:white">' + str + "<br/>" + obj + "</div>"; //args.target.innerText; //
    },
    tooltipContent() {
      //alert(JSON.stringify(args))
      this.$refs.grid.$el.addEventListener("mouseover", (args) => {
        if (args.target.closest("td")) {
          //alert(JSON.stringify(args.target.innerText))
          this.$refs.tooltip.content = args.target.innerText; // changing the tooltip content with the cell value
        }
      });
    },
    GenerateSQLStatement(operation) {
      const sql = generateSQLStatement(
        this.tableName,
        this.table.columns,
        this.table.primaryKey,
        operation
      );
      this.code = sql;
    },
    dataBound: function () {
      let gridInstance = this.$refs.grid.ej2Instances;
      gridInstance.autoFitColumns();
      //gridInstance.width = gridInstance.getContentTable().offsetWidth; // Set Grid content width to Grid width
    },
    dataBoundImport: function () {
      let gridInstance = this.$refs.gridImport.ej2Instances;
      gridInstance.autoFitColumns(); //gridInstance.width = gridInstance.getContentTable().offsetWidth; // Set Grid content width to Grid width
    },
    dataSourceChanged: function (state) {
      // if (state.action === "add") {
      //   this.orderService.addRecord(state).subscribe(() => state.endEdit());
      // } else if (state.action === "edit") {
      //   this.orderService.updateRecord(state).subscribe(() => state.endEdit());
      // } else if (state.requestType === "delete") {
      //   this.orderService.deleteRecord(state).subscribe(() => state.endEdit());
      // }
      state.endEdit();
    },
    dataStateChange: function (state) {
      let vm = this;
      let connection = this.connection;
      if (
        state.action.requestType == "searching" ||
        state.action.requestType == "paging" ||
        state.action.requestType == "sorting" ||
        state.action.requestType == "filtering"
      ) {
        //alert(state.sorted)
        if (state.sorted != undefined) {
          connection.sortField = state.sorted[0].name;
          connection.sortDirection = state.sorted[0].direction;
        }
        if (state.search == undefined) {
          //alert("search is undefined");
          getRecordsByState(connection, state).then(function (table) {
            return (vm.table = table);
          });
        } else {
          //alert("search is defined");
          const tableName = this.table.tableName;
          connection.searchOperator = state.search[0].operator;
          connection.searchValue = state.search[0].key;
          getRecordsBySearch(connection, state).then(function (table) {
            return (vm.table = table), (vm.table.tableName = tableName);
          });
        }
      }
      //else {
      //   getRecordsByState(connection, state).then(function (table) {
      //       return (vm.table = table);
      //     });
      // }
      if (state.action.requestType == "refresh") {
        return false;
      }
    },
    getTableRecordsByState(state) {
      //alert(JSON.stringify(state));
      this.showProgress = true;
      const vm = this;
      let connection = this.connection;
      if (state !== undefined) {
        connection.state = state;
      } else {
        let state = { skip: 0, take: this.settings.pageSize };
        connection.state = state;
      }
      //alert(JSON.stringify(state))
      Api()
        .put(apiUrl + "api/table/" + connection.tableName, connection)
        .then((response) => {
          let table = response.data;
          table.clientColumns = getFields(table);
          table.data = { result: table.rows, count: 100 };
          //this.$store.commit("setTable", req);
          this.showProgress = false;
          this.showDialog = false;
          return (this.table = table);
        })
        .catch(function (ex) {
          if (ex.response != undefined) {
            return (
              (vm.showProgress = false),
              (vm.showDialog = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "failure"),
              (vm.alert.text = ex.response.data.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.showDialog = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "failure"),
              (vm.alert.text = ex.message)
            );
          }
        });
    },
    saveQuery() {
      const sqlQuery = {
        description: this.queryName,
        statement: this.code,
        database: this.connection.database,
        date: new Date(), //.toLocaleString()
      };
      console.log(sqlQuery)
      this.$store.commit("setSqlQueries", sqlQuery);
    },
    generateSelectStatement() {},
    createEditorFrom(selector, mode, value) {
      var instance = CodeMirror.fromTextArea(selector, {
        lineNumbers: true,
        mode: "x-sql",
        tabSize: 4,
        line: true,
        extraKeys: { Ctrl: "autocomplete" },
      });
      instance.setOption("mode", mode);
      // make sure the CodeMirror unit expands by setting a null character
      instance.setValue("\0");
      instance.setValue(value);
      instance.on("change", (coder) => {
        this.code = coder.getValue();
        if (this.$emit) {
          this.$emit("input", this.code);
        }
      });
      return instance;
    },
    getSampleCode() {
      const mysql =
        "SELECT * FROM `" + this.tableName + "` LIMIT 0, 100";
      const mssql = "SELECT TOP 100 * FROM " + this.tableName;
      const pgsql =
        "select * from " + this.tableName + " fetch first 100 rows only";
      let code;
      if (this.code == "") {
        if (this.connection.dbType == "mysql") {
          code = mysql;
        } else if (this.connection.dbType == "mssql") {
          code = mssql;
        } else if (this.connection.dbType == "pgsql") {
          code = pgsql;
        } else {
          code = "SELECT * FROM " + this.table.tablename;
        }
      } else {
        code = this.code;
      }
      return (this.sheet = false), (this.code = code);
    },
    showSqlPanel() {
      this.sqlsheet = true;
      this.$nextTick(function () {
        this._initialize;
      });
      this.getSampleCode();
    },
    executeSql() {
      //alert(this.code);
      let connection = this.connection;
      connection.sqlQuery = this.code.trim();
      let vm = this;
      //alert(connection.sqlQuery);
      this.showProgress = true;
      //this.connection.tableName = this.queryName;
      try {
        Api()
          .post(apiUrl + "api/sqlquery", connection)
          .then((response) => {
            let table = response.data;
            
            //alert(table.data.result.length)
            table.clientColumns = getFields(table);
            table.clientCount = table.data.result.length;
            table.data.count = table.data.result.length;
            vm.table = table;
            
            vm.alert.text = "sql query was executed sucessfully";
            vm.alert.title = "SQL query success";
            vm.showProgress = false;
            vm.alert.bgcolor = "primary";
            vm.alert.snackbar = true;
            vm.showDialog = false;
            vm.sqlsheet = false;

            this.saveQuery();
          })
          .catch(function (ex) {
            if (ex.response != undefined) {
              vm.alert.title = "SQL query failed";
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (vm.showDialog = false),
          (vm.alert.alertDialog = true),
          (vm.alert.bgcolor = "failure"),
          (vm.alert.text = e.message),
          (vm.alert.title = tableName)
        );
      }
    },
    load() {
      return "go";
    },
    UpdateJSON(jsonArray, item) {
      for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].RegistrationID == item.RegistrationID) {
          jsonArray[i] = item;
          return jsonArray;
        }
      }
    },
    saveEdits(item) {
      let i;
      let pKeyValue;
      for (i = 0; i < item.length; i++) {
        if (item[i].field == this.table.primaryKey) {
          pKeyValue = item[i].value;
        }
      }
      // for (i = 0; i < item.length; i++) {
      //editData = editData + item[i].field + ":" + item[i].value + "";
      let editData = "{ ";
      item.forEach(function (col, index) {
        if (index == item.length - 1) {
          editData = editData + '"' + col.field + '":"' + col.value + '" }';
        } else {
          editData = editData + '"' + col.field + '":"' + col.value + '",';
        }
      });
      editData = JSON.parse(editData);
      //alert(JSON.stringify(editData));

      let connection = this.connection;
      connection.editData = editData;
      connection.pKeyValue = pKeyValue;
      connection.primaryKey = this.table.primaryKey;
      connection.pKeyField = this.table.primaryKey;
    },
    showAlert(
      alertTitle = "Table Records",
      alertText,
      showSnackbar = true,
      showAlertDialog = false,
      bgcolor = "primary",
      showProgress = false
    ) {
      this.alert.text = alertText;
      this.alert.title = alertTitle;
      this.showProgress = showProgress;
      this.alert.bgcolor = bgcolor;
      this.alert.alertDialog = showAlertDialog;
      this.alert.snackbar = showSnackbar;
    },
    delete(data) {
      const vm = this;
      vm.showDialog = false;
      return Api()
        .post(apiUrl + "api/table/delete", data)
        .then((response) => {
          return response;
        });
    },
    update(data) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(data));
      try {
        return Api()
          .put(apiUrl + "api/table/update/" + data.pKeyValue, data)
          .then((response) => {
            vm.alert.text =
              "Record with " +
              data.primaryKey +
              " = " +
              data.pKeyValue +
              " was updated successfully";
            vm.alert.title = "Table Record Update";
            vm.showProgress = false;
            vm.alert.bgcolor = "success";
            //vm.alert.alertDialog = true;
            vm.alert.snackbar = true;
            vm.showDialog = false;
            return response.data.message;
          })
          .catch(function (ex) {
            vm.$refs.grid.ej2Instances.refresh();
            if (ex.response != undefined) {
              vm.alert.title = "Record Update Failed";
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "failure"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    insert(insertData) {
      const vm = this;
      this.showProgress = true;
      //alert(JSON.stringify(insertData));
      try {
        Api()
          .post(apiUrl + "api/table/insert", insertData)
          .then((response) => {
            vm.alert.text = response.message;
            vm.$refs.grid.ej2Instances.refresh();
            return (
              (vm.showProgress = false),
              //(vm.alert.alertDialog = true),
              (vm.alert.snackbar = true),
              (vm.alert.bgcolor = "primary"),
              (vm.alert.text = "Record was inserted successfully"),
              (vm.showDialog = false)
            );
          })
          .catch(function (ex) {
            vm.$refs.grid.ej2Instances.refresh();
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "failure"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "failure"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    insertBulk() {
      const vm = this;
      this.showProgress = true;
      let connection = this.connection;
      connection.bulkInsert = this.csvJson;
      const bulkInsert = deleteUneditableColumns(
        this.csvJson,
        this.table.columns
      );
      connection.bulkInsert = bulkInsert;
      Api()
        .post(
          apiUrl + "api/" + connection.tableName + "/InsertBulk",
          connection
        )
        .then((response) => {
          vm.showImportCsvDialog = false;
          vm.alert.text = response.message;
          vm.showAlert(
            "Data saved Successful",
            "Record(s) was/were inserted successfully",
            true
          );
          return vm.getTableRecords();
        })
        .catch(function (ex) {
          if (ex.response !== undefined) {
            return vm.showAlert(
              "Saving Data Failed",
              ex.response.data.message,
              false,
              true,
              "failure"
            );
          } else {
            return vm.showAlert(
              "Saving Data Failed",
              ex.message,
              false,
              true,
              "failure"
            );
          }
        });
    },
    toolbarClick: function (args) {
      switch (args.item.text) {
        case "PDF Export":
          this.$refs.grid.pdfExport();
          break;
        case "Excel Export":
          this.$refs.grid.excelExport();
          break;
        case "CSV Export":
          this.$refs.grid.csvExport();
          break;
      }
    },
    exportToCSV() {
      this.$refs.grid.showSpinner();
      this.$refs.grid.csvExport();
    },
    exportToPDF() {
      this.$refs.grid.showSpinner();
      this.$refs.grid.pdfExport();
    },
    exportToExcel() {
      this.$refs.grid.showSpinner();
      this.$refs.grid.pdfExport();
    },
    csvExportComplete() {
      this.$refs.grid.hideSpinner();
    },
    pdfExportComplete() {
      this.$refs.grid.hideSpinner();
    },
    excelExportComplete() {
      this.$refs.grid.hideSpinner();
    },
    pushSelectedRow(item) {
      const payload = { record: item, tablename: this.connection.tablename };
      this.$store.commit("setSelectedRow", payload);
    },
    onRowSelected: function (args) {
      if (this.multipleSelection == true) {
        return;
      }
      this.detailDialog = true;
      if (
        args.requestType !== "beginEdit" ||
        args.requestType !== "add" ||
        args.requestType !== "delete"
      ) {
        this.selection = args.data;
      }
    },
    actionComplete(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        let dialog = args.dialog;
        if (window.innerWidth < 400) {
          dialog.height = 80 + "%";
          dialog.width = 85 + "%";
        } else {
          dialog.height = 70 + "%";
          dialog.width = 60 + "%";
        }
        // change the header of the dialog
        dialog.header =
          args.requestType === "beginEdit"
            ? "Record of " + args.rowData[this.table.primaryKey]
            : "New " + this.table.tableName;
      }
    },
    actionFailure: function (state) {
      alert("Server exception: 404 Not found" + alert(JSON.stringify(state)));
    },
    actionBegin: function (args) {
      let vm = this;
      if (args.requestType == "save") {
        if (args.action == "edit") {
          let connection = this.connection;
          connection.pKeyValue = args.data[this.table.primaryKey];
          connection.primaryKey = this.table.primaryKey;
          connection.pKeyField = this.table.primaryKey;
          const editData = deleteUneditableColumns(
            args.data,
            this.table.columns
          );
          connection.editData = editData;
          //alert(JSON.stringify(editData))
          this.update(connection); //.subscribe(() => args.endEdit());
          this.showProgress = false;
          args.endEdit();
          //this.orderService.updateRecord(state).subscribe(() => state.endEdit());
        }
        if (args.action == "add") {
          let connection = this.connection;
          const insertData = deleteUneditableColumns(
            args.data,
            this.table.columns
          );
          connection.editData = insertData;
          connection.insertData = insertData;

          this.insert(connection);
          args.endEdit();
        }
      }
      if (args.requestType == "delete") {
        let connection = this.connection;
        let pKeyValues = "";
        if (args.data.length > 1) {
          let i = 1;
          args.data.forEach(function (row) {
            if (i > 1) {
              pKeyValues += ", " + row[vm.table.primaryKey];
            } else {
              pKeyValues += row[vm.table.primaryKey];
            }
            i += 1;
          });
        } else {
          const selectedDelRecord = args.data[0];
          pKeyValues = selectedDelRecord[vm.table.primaryKey];
        }
        connection.pKeyValue = pKeyValues;
        connection.pKeyField = vm.table.primaryKey;
        this.delete(connection)
          .then(function (response) {
            //args.endEdit();
            vm.$refs.grid.hideSpinner();
            vm.showAlert(
              "Delete Records Successful",
              response.data.message,
              true
            );
            let selectedRowIndexes = vm.$refs.grid.getSelectedRowIndexes();
            //alert(JSON.stringify(selectedRowIndexes))
            let newData = vm.table.data.result;
            selectedRowIndexes.forEach(function (rowIndex) {
              newData.splice(rowIndex, 1);
            });
            // this.data = [...this.data];
            vm.table.data = { result: newData, count: newData.length };
            //vm.table.data.result = [...vm.table.data.result]
            vm.$refs.grid.ej2Instances.refresh();
            //vm.$refs.grid.ej2Instances.dataBind();
          })
          .catch(function (ex) {
            vm.$refs.grid.hideSpinner();
            if (ex.response == undefined) {
              vm.showAlert(
                "Delete Record Failed",
                ex.message,
                false,
                true,
                "failure"
              );
            } else {
              vm.showAlert(
                "Delete Record Failed",
                ex.response.data.message,
                false,
                true,
                "failure"
              );
            }
          });
      }
    },
    getTableRecords() {
      let vm = this;      
      let state = { skip: 0, take: this.pageSettings.take };
      if (this.connection.tableName !== undefined) {
        vm.showProgress = true;
        getRecordsByState(this.connection, state).then(function (table) {
          return (vm.table = table), (vm.showProgress = false);
        });
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
  },
  watch: {
    tableName(tableName) {
      if (tableName !== "" || tableName !== null || tableName !== undefined) {
        return this.getTableRecords();
      }
    },
    otable(table) {
      this.sheet = false;
      if (!this.connection.primaryKey) {
        this.editSettings.allowEditing = false;
      }
      return (this.table = table);
    },
    selectedQuery(query) {
      return (
        (this.code = query.statement), (this.queryName = query.description)
      );
    },
    queryName(queryName) {
      if (queryName.length > 0 && this.code.length > 5) {
        return (this.isQueryNameExist = true);
      } else {
        return (this.isQueryNameExist = false);
      }
    },
  },
  provide: {
    //TooltipPlugin,
    grid: [
      Filter,
      Page,
      Selection,
      Sort,
      Resize,
      ColumnMenu,
      Reorder,
      PdfExport,
      ExcelExport,
      Toolbar,
      Group,
      ContextMenu,
      Edit,
      CommandColumn,
      ColumnChooser,
      ForeignKey,
      Freeze,
      Group,
    ],
  },
  mounted() {
    //return this.getTableRecords();
  },
  created() {
    this.showProgress = true;
    this.showDialog = false;
    this.$store.commit("loadSqlQueries");
    var settings = this.settings;
    if (settings == null) {
      settings = {
        noOfRows: 10,
        editMode: "Dialog",
        pageSize: "10",
      };
    }
   
    return (
      (this.pageSettings.take = settings.noOfRows),
      (this.editSettings.mode = settings.editMode),
      (this.pageSettings.pageSize = settings.pageSize),
      this.getTableRecords()
    );
  },
});

function getRecordsBySearch(connection, state) {
  connection.state = state;
  return Api()
    .post(apiUrl + "api/table/search", connection)
    .then((response) => {
      let table = response.data;
      table.clientColumns = getFields(table);
      //table.data = { result: table.rows, count: table.data.count };
      return table;
    })
    .catch(function () {
      return false;
    });
}
function getRecordsByState(connection, state) {
  //alert(JSON.stringify(connection));
  connection.state = state;
  return Api()
    .post(apiUrl + "api/table", connection)
    .then((response) => {
      let table = response.data;
      table.clientColumns = getFields(table);
      //table.data = { result: table.rows, count: table.data.count };
      return table;
    })
    .catch(function () {
      return false;
    });
}
</script>
<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
.image img {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  box-shadow: inset 0 0 1px #e0e0e0, inset 0 0 1px rgba(0, 0, 0, 0.2);
}
.e-edit-dialog .e-dlg-header-content {
  /* background-color: rgba(28, 96, 184, 0.618); */
  /* border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: rgb(216, 214, 214); */
  margin-bottom: 5px;
  box-shadow: 0 3px 4px -3px;
}
.e-grid .e-altrow {
  background-color: rgba(246, 249, 252);
}
/* CodeMirror */
.remove-padding {
  margin-left: -18px;
  margin-top: -15px;
  margin-right: -17px;
  margin-bottom: -15px;
  z-index: 0;
}
.CodeMirror-hints,
.CodeMirror-hint,
.CodeMirror-lint-tooltip .CodeMirror-hint-active {
  z-index: 2147483647 !important;
  position: relative !important;
  background: white !important;
}

.customtip.e-tooltip-wrap {
  border-radius: 0px;
  opacity: 1;
}

.customtip.e-tooltip-wrap.e-popup {
  padding: 7px;
  background-color: #fff;
  border: 0px solid #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

.customtip.e-tooltip-wrap .e-tip-content {
  max-height: 300px;
  max-width: 300px;

  height: auto;
  overflow: auto;
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
  height: 20px;
  width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-top {
  height: 20px;
  width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-left {
  height: 12px;
  width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-right {
  height: 12px;
  width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 20px solid #fff;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
  border-bottom: 20px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {
  border-bottom: 6px solid transparent;
  border-right: 12px solid #fff;
  border-top: 6px solid transparent;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-right {
  border-bottom: 6px solid transparent;
  border-left: 12px solid #fff;
  border-top: 6px solid transparent;
}
</style>
