import Vue from "vue";
import VueRouter from "vue-router";

import Database from "../views/Database.vue";
import Login from "../views/Login.vue";
const SignUp = () => import("../views/SignUp.vue");
const ForgetPassword = () => import("../views/RecorverPassword.vue");
const VerifyOTP = () => import("../views/VerifyOTP.vue");
const Settings = () => import("../views/Settings.vue");
const Tables = () => import('../views/Tables')
//const SSRecords = () => import("../views/SSRecords.vue");
import SSRecords from "../views/SSRecords.vue";
const CSRecords = () => import("../views/CSRecords.vue");
const Design = () => import("../views/Design.vue");
const ChangePassword = () => import("../views/ChangePassword.vue");
const SetPassword = () => import("../views/SetPassword.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const Developer = () => import("../views/Developer.vue");
//import EntityRelationship = () => import( "../views/EntityRelationship.vue";
const Query = () => import('../views/Query.vue')
const map = () => import('../views/map.vue')

// const UserDetails = () =>
// import(/* webpackChunkName: "group-user" */ './UserDetails.vue')
// const UserDashboard = () =>
// import(/* webpackChunkName: "group-user" */ './UserDashboard.vue')


Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Database",
    component: Database,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/design",
    name: "Design",
    component: Design,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/query",
    name: "Query",
    component: Query,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  // {
  //   path: "/entityrelationship",
  //   name: "EntityRelationship",
  //   component: EntityRelationship,
  //   meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"]}
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: { requiresAuth: false }
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: { requiresAuth: false }
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: "/verifyotp",
    name: "VerifyOTP",
    component: VerifyOTP,
    meta: { requiresAuth: true }
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: "/setpassword",
    name: "SetPassword",
    component: SetPassword,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/ssrecords",
    name: "SSRecords",
    component: SSRecords,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/csrecords",
    name: "CSRecords",
    component: CSRecords,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: "/developer",
    name: "Developer",
    component: Developer,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"] }
  },
  {
    path: '/map',
    name: 'map',
    component: map,
    meta: { requiresAuth: true, roles: ["Preview", "Standard", "Team", "Developer", "Enterpise"]}
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("role");
  //alert(userName + ": " + userRole)
  if (to.matched.some(record => record.meta.requiresAuth == true) && !userName) {
    next({ name: 'Login' })
  }
  else if (to.matched.some(record => record.meta.requiresAuth == false)) {
    next()
  }
  else if (to.meta.roles) {
    if (to.meta.roles.includes(userRole)) {
      next();
    }
    else next({ name: 'accessdenied' });
  }
  else {
    if (router.currentRoute.name !== "Login") {
      next()
    }
  };
  // if (to.meta.requiresAuth) {
  //   alert(to.name + " " + from.name)
  //   if (to.name !== 'Login' && !userName) {
  //     next({
  //       path: '/login',
  //       replace: true
  //     })
  //   } else if (to.meta.roles) {
  //      if (to.meta.roles.includes(userRole)) {
  //       next();
  //     }
  //     else next({ name: 'accessdenied' });
  //   } else {
  //     alert("working...")
  //     next()
  //   }
  // } else next()
});

export default router;
