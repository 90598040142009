import axios from 'axios'
import AppSettings from './app.settings'

export default () => {
  return axios.create({
    baseURL: AppSettings.API_ENDPOINT,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
}
