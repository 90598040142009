// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.blue,
        secondary: colors.blue.darken1, // #FFCDD2
        accent: colors.yellow, // #3F51B5
        danger: colors.red.lighten2,
        warn: colors.orange.base,
        progress: colors.grey.base
      },
    },
  }
}

export default new Vuetify(opts)
