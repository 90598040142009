import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/Authentication";
//import startUp from './modules/MenuConfig'
//import AdamsCollege from './modules/AdamsCollege'
import flexiblebase from "./modules/Flexiblebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    date: new Date().toLocaleString(),
    //new Date().toLocaleDateString();
    //new Date().toLocaleTimeString();
    progress: { visible: false, message: "" },
    success: { show: false, message: "", duration: 3000 },
    statusText: "",
    alert: {
      alertDialog: false,
      bgcolor: "",
      title: "Database Conection",
      mode: "",
      snackbar: false,
      text: "",
      timeout: 4000,
      x: "right",
      y: "top"
    }
  },
  getters: {
    getAlert: state => {
      return state.alert;
    },
    getStatusText: state => {
      return state.statusText;
    },
    getProgressStatus: state => {
      return state.progress;
    }
  },
  mutations: {
    setStatusText(state, payload) {
      state.statusText = payload.statusText;
    },
    setAlert(state, alert) {
      state.alert = alert;
    },
    setError(state, message) {
      state.error.message = message;
      state.error.show = true;
    },
    setProgressStatus: (state, payload) => {
      state.progress = payload;
    }
  },
  actions: {},
  modules: {
    authentication,
    //startUp,
    flexiblebase
  }
});
