import Vue from "vue";
import Api from "../app.settings.network";
import AppSettings from "../app.settings";
//import linq from "linq"
var Enumerable = require('linq');

const apiUrl = AppSettings.API_ENDPOINT;
/* Utility functions to create a nested, multilevel grouped, ordered 
 * structure out of a flat array of uniform objects.
 * 
 * usage: result = applyStructure(items, structure);
 *
 * structure is an object of the form:
 * {
 *     group: "propertyName1"
 *     order: ["propertyName1", "propertyName2 DESC", ...]
 *     next: {
 *         group: "propertyName3"
 *         order: ["propertyName3 DESC"]
 *         next: {
 *             // etc
 *         }
 *     }
 * }
 *
 * also see http://jsfiddle.net/Tomalak/xth6ayuo/
 */

// Enumerable, definition => Enumerable
function applyOrder(items, definition) {
  var i, prop, prefix, suffix, orderFunc;
  if (!items) return;
  if (!definition) return items;
  for (i = 0; i < definition.length; i++) {
    // definition[i] is either "propertyName" or "propertyName DESC"
    prop = (definition[i] + " ").split(" ");
    prefix = i === 0 ? "orderBy" : "thenBy";
    suffix = prop[1].toUpperCase() === "DESC" ? "Descending" : "";
    orderFunc = prefix + suffix;
    items = items[orderFunc]("$." + prop[0]);
  }
  return items;
}
function randomInt(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}
// Enumerable, definition => Enumerable
function applyGroup(items, definition) {
  if (!items) return;
  if (!definition) return items;
  items = applyOrder(items, definition.order);
  if (!definition.group) return items;
  return items.groupBy("$." + definition.group, "", function (key, e) {
    return {
      //group: definition.group,
      top: randomInt(100, 1000),
      left: randomInt(50, 2000),
      id: key,
      title: key,
      fields: applyGroup(e, definition.then).toArray()
    };
  });
}

// Array, definition => Array
export const applyGroupBy = function (items, definition) {
  if (!items) return;
  if (!definition) return items;
  return applyGroup(Enumerable.from(items), definition).toArray();
}

export const prettyJSON = function (json) {
  if (json) {
    json = JSON.stringify(json, undefined, 4);
    json = json
      .replace(/&/g, "&")
      .replace(/</g, "<")
      .replace(/>/g, ">");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }
};
export const getFormMarkup = function (tableName, design) {
  // var col = {
  //       field: item.field,
  //       type: item.type,
  //       size: item.dataSize,
  //       control: editType,
  //       label: item.field,
  //       validation: [],
  //       options: [],
  //   }
  let str = "";
  str += '<v-form v-model="valid">';
  str += "\n";
  str += " <v-row>";
  str += "\n";
  design.forEach(function (item) {
    if (item.field !== undefined) {
      if (item.control == "dropdown") {
        str += '<v-col cols="12" sm="6">';
        str += "\n";
        str += "  <v-select ";
        str += "\n";
        str += '    v-model="' + tableName + "." + item.field + '"';
        str += "\n";
        str += '    :items="' + item.options + '"';
        str += "\n";
        str += '    placeholder="Enter your ' + item.field + '"';
        str += "\n";
        str += '    :rules="[rules.required]"';
        str += "\n";
        str += "    dense";
        str += "\n";
        str += "    outlined>";
        str += "\n";
        str += "  </v-select>";
        str += "\n";
        str += "</v-col>";
        str += "\n";
      } else {
        str += '<v-col cols="12" sm="6">';
        str += "\n";
        str += "  <v-text-field ";
        str += "\n";
        str += '    type ="' + item.type + '"';
        str += "\n";
        str += '    v-model="' + tableName + "." + item.field + '"';
        str += "\n";
        str += '    label="' + item.label + '"';
        str += "\n";
        str += '    placeholder="Enter your ' + item.label + '"';
        str += "\n";
        str += '    :rules="[rules.required]"';
        str += "\n";
        str += "    dense";
        str += "\n";
        str += "    outlined>";
        str += "\n";
        str += "  </v-text-field>";
        str += "\n";
        str += "</v-col>";
        str += "\n";
        str += "\n";
      }
    }
  });
  str += "</v-row>";
  str += "\n";
  str += "</v-form>";
  str += "\n";
  str += "\n";
  return str;
};
export const getFormModel = function (tableName, design) {
  let str = "";
  str += tableName + ": {";
  str += "\n";
  design.forEach(function (item) {
    str += "  " + item.field + ': "",';
    str += "\n";
  });
  str += "}";
  str += "\n";
  return str;
};
export const getDataTable = function (tableName, design) {
  let str = "";
  str += "\n";
  str += "\n";
  str += "//for use as a model for the form above";
  str += "\n";
  str += "\n";
  str += tableName + ": {";
  str += "\n";
  design.forEach(function (item) {
    str += "  " + item.field + ': "",';
    str += "\n";
  });
  str += "}";
  str += "\n";
  return str;
};
export const generateSubmitFunction = function (tableName, design) {
  let str = "";
  str += "\n";
  str += "\n";
  str += "//for use as a model for the form above";
  str += "\n";
  str += "\n";
  str += tableName + ": {";
  str += "\n";
  design.forEach(function (item) {
    str += "  " + item.field + ': "",';
    str += "\n";
  });
  str += "}";
  str += "\n";
  return str;
};

export const getStates = function () {
  return [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara"
  ];
};

export const getRecords = function (connection, state) {
  connection.state = state;
  return Api()
    .put(apiUrl + "api/table/" + connection.tableName, connection)
    .then(response => {
      let table = response.data;
      table.clientColumns = getFields(table);
      table.clientCount = table.data.result.length;
      return table;
    })
    .catch(function () {
      return false;
    });
};

export const getDeveloperColumns = function (connection, state) {
  connection.state = state;
  let designColumns = [];
  return Api()
    .put(apiUrl + "api/table/" + connection.tableName, connection)
    .then(response => {
      let table = response.data;
      let gridColumns = getFields(table);

      gridColumns.forEach(function (item) {
        if (item.field !== undefined) {
          let editType, type;
          if (item.editType == "datepickeredit") {
            editType = "textbox";
          } else if (item.editType == "numericedit") {
            editType = "numericbox";
          } else if (item.editType == "dropdownedit") {
            editType = "dropdown";
          } else if (item.editType == "datepickeredit") {
            editType = "datepicker";
          }
          type = item.type;
          if (type == "string") {
            type = "text";
          } else if (type == "integer") {
            type = "number";
          } else if (type == "date") {
            type = "date";
          }
          var col = {
            field: item.field,
            type: type,
            size: item.dataSize,
            control: editType,
            label: item.field,
            validation: [],
            options: [],
            model: null,
            required: true,
            multiSelect: false,
            disabled: false,
            hint: "supply the value for " + item.field,
            prefix: "",
            suffix: "",
            counter: true,
            maxlength: item.dataSize,
            minlength: 0,
            icon: "",
            outlined: false
          };
          designColumns.push(col);
        }
      });
      return designColumns;
    })
    .catch(function (e) {
      return e.message;
    });
};
export const groupBy = function (data, key) {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function (storage, item) {
    // alert(JSON.stringify(item))
    // get the first instance of the key by which we're grouping
    var group = item[key];
    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || [];

    // add this item to its group within `storage`
    //let column = item
    storage[group].push(item);
    // return the updated storage to the reduce function, which will then loop through the next
    return storage;
    // var result = linq
    //     .groupBy(function (x) {
    //       return x.Country;
    //     })
    //     .select(function (x) {
    //       return {
    //         Country: x.key(),
    //         Customer: x.count(function (y) {
    //            return y.Country | 1;
    //         }),
    //         Revenue: x.sum(function (y) {
    //            return y.Country | 10000;
    //         }),
    //       };
    //     })
    //     .toArray();
  }, {}); // {} is the initial value of the storage
};
export const groupByColumn = function (data, category, measure, operator) {
  var result
  //alert(operator)
  if (operator == "count") {
    result = Enumerable.from(data)
      .groupBy("$." + category)
      .select(x => ({
        dimension: x.key(),
        count: x.count(),
        sum: x.sum("$." + measure),
        value: x.count(), //x.first("$." + measure),
        ave: x.average("$." + measure),
      })).toArray();
  } else if (operator == "sum") {
    result = Enumerable.from(data)
      .groupBy("$." + category)
      .select(x => ({
        dimension: x.key(),
        count: x.count(),
        sum: x.sum("$." + measure),
        value: x.sum("$." + measure), //x.first("$." + measure),
        ave: x.average("$." + measure),
      })).toArray();
  } else if (operator == "average") {
    result = Enumerable.from(data)
      .groupBy("$." + category)
      .select(x => ({
        dimension: x.key(),
        count: x.count(),
        sum: x.sum("$." + measure),
        value: x.average("$." + measure), // x.first("$." + measure),
        ave: x.average("$." + measure),
      })).toArray();
  } else {
    result = Enumerable.from(data)
      .groupBy("$." + category)
      .select(x => ({
        dimension: x.key(),
        count: x.count(),
        sum: x.sum("$." + measure),
        value: x.first("$." + measure),
        ave: x.average("$." + measure),
      })).toArray();
  }

  console.log(result)
  return result
  //rows: x.getSource(),
  // table: x.tableName,
  // columns: {
  //   columnName: x.columnName,
  // }
  // country: x.key(),
  // Customer: x.count(function (y) {
  //    return y.Country | 1;
  // }),
};
export const timeSince = date => {
  let ndate;
  try {
    ndate = new Date(date);
  } catch (ex) {
    ndate = date;
  }
  var seconds = Math.floor((new Date() - ndate) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const encriptPass = plainPassword => {
  const a = Vue.CryptoJS.AES.encrypt(
    plainPassword,
    localStorage.getItem("userName")
  ).toString();
  //alert(a);
  return a;
};
export const decriptPass = encriptedPassword => {
  var decripted = ""
  try {
    decripted = Vue.CryptoJS.AES.decrypt(
      encriptedPassword,
      localStorage.getItem("userName")
    ).toString(Vue.CryptoJS.enc.Utf8);
  } catch (error) {
  }
  return decripted; 
};
export const deleteUneditableColumns = (data, columns) => {
  try {
    columns.forEach(function (col) {
      //alert(JSON.stringify(col))
      const dtype = col.dataType.toLowerCase();
      const generated = col.generated;
      const isIdentity = col.isIdentity;
      //alert(col.columnName + ", " + isIdentity)
      if (
        dtype == "image" ||
        dtype == "byte" ||
        dtype == "bytes" ||
        dtype == "bytea" ||
        dtype == "binary" ||
        dtype == "varbinary" ||
        dtype == "blob" ||
        dtype == "tinyblob" ||
        dtype == "longblob" ||
        dtype == "geography" ||
        dtype == "geometry" ||
        dtype == "point" ||
        dtype == "linestring" ||
        dtype == "polygon" ||
        dtype == "multipoint" ||
        dtype == "pultilinestring" ||
        dtype == "multipolygon" ||
        dtype == "geometrycollection" ||
        dtype == "json" ||
        dtype == "line" ||
        dtype == "lseg" ||
        dtype == "box" ||
        dtype == "path" ||
        dtype == "circle" ||
        generated == "auto_increment" ||
        generated == "on update CURRENT_TIMESTAMP" ||
        generated == "STORED GENERATED" ||
        generated == "VIRTUAL GENERATED" ||
        generated == "DEFAULT_GENERATED" ||
        isIdentity == "auto_increment"
      ) {
        //delete data[col.columnName];
        for (var i = 0; i < data.length; i++) {
          delete data[i][col.columnName];
        }
        alert(JSON.stringify(data))
      }
    });
    return data;
  } catch (ex) {
    //alert(ex.message);
    return data;
  }
};

export const generateSQLStatement = (
  tableName,
  columns,
  primaryKey,
  operation
) => {
  let i = 0;
  let sql = "";
  try {
    if (operation == "SELECT") {
      i = 0;
      sql = "SELECT ";
      columns.forEach(function (col) {
        if (i == 0) {
          sql += "[" + col.columnName + "]";
        } else {
          sql += ", ";
          //put space after every sixth column
          var remainder = i % 6;
          if (remainder == 0) {
            sql += "\n";
            sql += " ";
          }
          sql += "[" + col.columnName + "]";
        }
        i++;
      });
      sql += "\n";
      sql += "FROM " + "[" + tableName + "]";
      return sql;
    } else if (operation == "UPDATE") {
      i = 0;
      sql = "UPDATE [" + tableName + "]";
      sql += "\n";
      sql += "SET ";
      columns.forEach(function (col) {
        if (i == 0) {
          sql +=
            "[" + col.columnName + "] = @" + col.columnName.replace(/\s/g, "_");
        } else {
          sql += ", ";
          var remainder = i % 3;
          if (remainder == 0) {
            sql += "\n";
            sql += "  ";
          }
          sql +=
            "[" + col.columnName + "] = @" + col.columnName.replace(/\s/g, "_");
        }
        i++;
      });
      sql += "\n";
      sql += "WHERE [" + primaryKey + "] = @" + primaryKey.replace(/\s/g, "_");
      return sql;
    } else if (operation == "INSERT") {
      i = 0;
      sql = "";
      sql += "INSERT INTO [" + tableName + "]";
      sql += " (";
      sql += "\n";
      columns.forEach(function (col) {
        if (i == 0) {
          sql += "  [" + col.columnName + "]";
        } else {
          sql += ", ";
          //put space after every sixth column
          var remainder = i % 6;
          if (remainder == 0) {
            sql += "\n";
            sql += "  ";
          }
          sql += "[" + col.columnName + "]";
        }
        i++;
      });
      sql += ")";
      sql += "\n";
      sql += "VALUES(";
      i = 0;
      columns.forEach(function (col) {
        if (i == 0) {
          sql += "@" + col.columnName.replace(/\s/g, "_");
        } else {
          sql += ", ";
          //put space after every sixth column
          var remainder = i % 6;
          if (remainder == 0) {
            sql += "\n";
            sql += "  ";
          }
          sql += "@" + col.columnName.replace(/\s/g, "_");
        }
        i++;
      });
      sql += ")";
      return sql;
    } else if (operation == "DELETE") {
      sql = "DELETE FROM " + tableName;
      sql += "\n";
      sql += " WHERE ";
      sql += "[" + primaryKey + "] = @" + primaryKey.replace(/\s/g, "_");
      return sql;
    } else if (operation == "STRUCTURE") {
      i = 0;
      sql = "CREATE TABLE [" + tableName + "] (";
      sql += "\n";
      //conpose the create fields
      columns.forEach(function (col) {
        const datatype = col.dataType.toLowerCase();
        //const generated = col.generated
        const isIdentity = col.isIdentity;
        if (isIdentity == "YES") {
          sql +=
            "[" + col.columnName + "] " + "int IDENTITY(1,1) NOT NULL" + ",";
          sql += "\n";
        } else {
          let nullable = "NOT NULL";
          let dataType = "";
          if (col.nullable == true) {
            nullable = "NULL";
          }
          if (
            datatype == "int" ||
            datatype == "float" ||
            datatype == "datetime" ||
            datatype == "image" ||
            datatype == "bit" ||
            datatype == "datetime" ||
            datatype == "geography" ||
            datatype == "text" ||
            datatype == "ntext" ||
            datatype == "geometry" ||
            datatype == "uniqueidentifier" ||
            datatype == "smallint" ||
            datatype == "money" ||
            datatype == "real"
          ) {
            dataType = "[" + datatype + "]";
          } else {
            dataType = "[" + datatype + "](" + col.size + ")";
          }
          sql +=
            "  [" + col.columnName + "] " + dataType + " " + nullable + ",";
          sql += "\n";
        }
      });
      //Create primary key
      if (primaryKey) {
        sql +=
          "  Constraint [PK_" +
          tableName +
          "] PRIMARY KEY ([" +
          primaryKey +
          "])";
      }
      //Create Unique keys
      columns.forEach(function (col) {
        if (col.unique == true && col.primary == false) {
          sql += ", ";
          sql += "\n";
          sql +=
            "  Constraint [IX_" +
            tableName +
            "_" +
            col.columnName +
            "] UNIQUE NONCLUSTERED ([" +
            col.columnName +
            "])";
        }
      });
      sql += "\n";
      sql += ")";
      sql += "\n";
      return sql;
    }
  } catch (ex) {
    return ex.message;
  }
  //else if (operation == "DESCRIBE") {
  //sql = "SELECT COLUMN_NAME AS columnName, IS_NULLABLE AS isNullable, DATA_TYPE AS dataType, " +
  //"CHARACTER_MAXIMUM_LENGTH AS size, '' AS generated, '' AS isIdentity from INFORMATION_SCHEMA.COLUMNS " +
  //"WHERE TABLE_NAME = '" + tableName + "' AND column_name IN (" + concatenateFields(columns) + ")";
};

export const generateSQLfromGUI = (
  tables,
  columns,
  primaryKey, dbType
) => {
  let i = 0;
  let sql = "";
  i = 0;
  let qtL, qtR = ""
  if (dbType == 'mssql') {
    qtL = ""
    qtR = ""
  } else if (dbType == "mysql") {
    qtL = ""
    qtR = ""
  } else {
    qtL = ""
    qtR = ""
  }
  sql = "SELECT ";
  //alert(JSON.stringify(columns))
  columns.forEach(function (col) {
    if (i == 0) {
      sql += qtL + col + qtR;
    } else {
      sql += ', ';
      //put space after every sixth column
      var remainder = i % 6;
      if (remainder == 0) {
        sql += '\n';
        sql += ' ';
      }
      sql += qtL + col + qtR;
    }
    i++;
  });
  sql += '\n';

  if (dbType == 'mssql') {
    qtL = "["
    qtR = "]"
  } else if (dbType == "mysql") {
    qtL = "`"
    qtR = "`"
  } else {
    qtL = ""
    qtR = ""
  }

  sql += 'FROM ' + qtL + tables[0] + qtR;

  if (tables.length > 1) {
    tables.forEach((table, index) => {
      if (index !== 0) {
        sql += '\n';
        sql += ' INNER JOIN ' + qtL + table + qtR + ' ON ' + qtL + tables[0] + qtR + '.' + primaryKey + ' = ' + qtL + table + qtR + '.' + primaryKey
      }
    })
  }
  return sql;
  //else if (operation == "DESCRIBE") {
  //sql = "SELECT COLUMN_NAME AS columnName, IS_NULLABLE AS isNullable, DATA_TYPE AS dataType, " +
  //"CHARACTER_MAXIMUM_LENGTH AS size, '' AS generated, '' AS isIdentity from INFORMATION_SCHEMA.COLUMNS " +
  //"WHERE TABLE_NAME = '" + tableName + "' AND column_name IN (" + concatenateFields(columns) + ")";
};

export const generateImportSchema = (
  columns
) => {
  let fields = [];
  columns.forEach(function (col) {
    const isIdentity = col.isIdentity;
    //const isPrimaryKey = col.primaryKey
    const dtype = col.dataType.toLowerCase();
    const generated = col.generated;
    const nullable = col.nullable;
    if (isIdentity == "YES" ||
      dtype == "image" ||
      dtype == "byte" ||
      dtype == "bytes" ||
      dtype == "bytea" ||
      dtype == "binary" ||
      dtype == "varbinary" ||
      dtype == "blob" ||
      dtype == "tinyblob" ||
      dtype == "longblob" ||
      dtype == "geography" ||
      dtype == "geometry" ||
      dtype == "point" ||
      dtype == "linestring" ||
      dtype == "polygon" ||
      dtype == "multipoint" ||
      dtype == "pultilinestring" ||
      dtype == "multipolygon" ||
      dtype == "geometrycollection" ||
      dtype == "json" ||
      dtype == "line" ||
      dtype == "lseg" ||
      dtype == "box" ||
      dtype == "path" ||
      dtype == "circle" ||
      generated == "auto_increment" ||
      generated == "on update CURRENT_TIMESTAMP" ||
      generated == "STORED GENERATED" ||
      generated == "VIRTUAL GENERATED" ||
      generated == "DEFAULT_GENERATED" ||
      isIdentity == "YES"
    ) { fields.push({}) }
    else {
      var field = { columnName: col.columnName, dataType: dtype, size: col.size, required: nullable }
      fields.push(field)
    }
  })
  return fields;
  //else if (operation == "DESCRIBE") {
  //sql = "SELECT COLUMN_NAME AS columnName, IS_NULLABLE AS isNullable, DATA_TYPE AS dataType, " +
  //"CHARACTER_MAXIMUM_LENGTH AS size, '' AS generated, '' AS isIdentity from INFORMATION_SCHEMA.COLUMNS " +
  //"WHERE TABLE_NAME = '" + tableName + "' AND column_name IN (" + concatenateFields(columns) + ")";
};

/**
 * Takes a raw CSV string and converts it to a JavaScript object.
 * @param {string} string The raw CSV string.
 * @param {string[]} headers An optional array of headers to use. If none are
 * given, they are pulled from the file.
 * @param {string} quoteChar A character to use as the encapsulating character.
 * @param {string} delimiter A character to use between columns.
 * @returns {object[]} An array of JavaScript objects containing headers as keys
 * and row entries as values.
 */
export const csvToJson = (string, headers, quoteChar = '"', delimiter = ',') => {
  const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');
  const match = string => [...string.matchAll(regex)].map(match => match[2])
    .filter((_, i, a) => i < a.length - 1); // cut off blank match at end

  const lines = string.split('\n');
  const heads = headers || match(lines.splice(0, 1)[0]);

  return lines.map(line => match(line).reduce((acc, cur, i) => ({
    ...acc,
    [heads[i] || `extra_${i}`]: (cur.length > 0) ? (Number(cur) || cur) : null
  }), {}));
}

// function concatenateFields(columns) {
//   var sql = ""
//   columns.forEach(function (col) {
//     if (i == 0) {
//       sql += col.columnName
//     } else {
//       sql += "," + col.columnName
//     }
//     i++
//   });
//   return sql;
// }
// function concatenateParameters(columns) {
//   var sql = ""
//   columns.forEach(function (col) {
//     if (i == 0) {
//       sql += "@" + col.columnName
//     } else {
//       sql += ",@" + col.columnName
//     }
//     i++
//   });
//   return sql
// }
export const getFields = table => {
  if (table == null) {
    return;
  }
  const newColumnList = [];
  const checkcolumn = {
    type: "checkbox",
    allowFiltering: false,
    allowSorting: false,
    allowEditing: false,
    width: "60"
  };
  const commands = [
    {
      type: "Edit",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-edit e-icons"
      }
    },
    {
      type: "Delete",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-delete e-icons"
      }
    },
    {
      type: "Save",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-update e-icons"
      }
    },
    {
      type: "Cancel",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-cancel-icon e-icons"
      }
    }
  ];
  const commandcolumn = {
    headerText: "Actions",
    textAlign: "Center",
    width: "100",
    commands: commands
  };
  newColumnList[0] = checkcolumn;
  newColumnList[1] = commandcolumn;

  const primaryKey = table.primaryKey;
  const req = table.columns;
  //alert(JSON.stringify(req))
  if (req != undefined) {
    req.forEach(function (item, i) {
      let dtype = item.dataType.toLowerCase();
      let size = item.size;
      let dataType;
      let editType;
      let allowEditing = true;
      let template;
      if (
        dtype == "character varying" ||
        dtype == "character" ||
        dtype == "varchar" ||
        dtype == "char" ||
        dtype == "nvarchar" ||
        dtype == "nchar" ||
        dtype == "text" ||
        dtype == "string" ||
        dtype == "oid"
      ) {
        dataType = "string";
        editType = "textedit";
        allowEditing = true;
      } else if (
        dtype == "integer" ||
        dtype == "bigint" ||
        dtype == "int" ||
        dtype == "serial" ||
        dtype == "smallint"
      ) {
        dataType = "integer";
        editType = "numericedit";
        allowEditing = true;
      } else if (
        dtype == "date" ||
        dtype == "datetime" ||
        dtype == "timestamp" ||
        dtype == "datetime2"
      ) {
        dataType = "date";
        editType = "datepickeredit";
        allowEditing = true;
      } else if (
        dtype == "float" ||
        dtype == "double" ||
        dtype == "number" ||
        dtype == "double precision" ||
        dtype == "numeric" ||
        dtype == "real" ||
        dtype == "single"
      ) {
        dataType = "number";
        editType = "numericedit";
        allowEditing = true;
      } else if (dtype == "bit" || dtype == "bool" || dtype == "bit varying") {
        dataType = "boolean";
        editType = "dropdownedit";
        allowEditing = true;
      } else if (
        dtype == "geography" ||
        dtype == "geometry" ||
        dtype == "point" ||
        dtype == "linestring" ||
        dtype == "polygon" ||
        dtype == "multipoint" ||
        dtype == "pultilinestring" ||
        dtype == "multipolygon" ||
        dtype == "geometrycollection" ||
        dtype == "json" ||
        dtype == "line" ||
        dtype == "lseg" ||
        dtype == "box" ||
        dtype == "path" ||
        dtype == "circle"
      ) {
        dataType = "string";
        editType = "textedit";
        allowEditing = false;
      } else if (
        dtype == "image" ||
        dtype == "byte" ||
        dtype == "bytes" ||
        dtype == "bytea" ||
        dtype == "binary" ||
        dtype == "blob" ||
        dtype == "tinyblob" ||
        dtype == "longblob" ||
        dtype == "varbinary"
      ) {
        editType = "textedit";
        let keyImage = item.columnName;
        const firstRowData = table.data.result[0];
        let mimiType;
        for (var key in firstRowData) {
          if (key == keyImage) {
            var mediaType;
            const mtype = firstRowData[key].charAt(0);
            if (mtype == "/") {
              mediaType = "image/jpg";
            } else if (mtype == "T") {
              mediaType = "image/tif";
            } else if (mtype == "i") {
              mediaType = "image/png";
            } else if (mtype == "R") {
              mediaType = "image/gif";
            } else if (mtype == "U") {
              mediaType = "image/webp";
            } else if (mtype == "J") {
              mediaType = "application/pdf";
            } else if (mtype == "P") {
              mediaType = "image/SVG";
            } else {
              mediaType = "image/png"
            }

            mimiType = 'data:' + mediaType + ";base64, ${" + keyImage + '}'
          }
        }
        //alert (mimiType)
        allowEditing = false;
        template = '<embed height="40px" src="' + mimiType + '"/>';
        //template = '<div class="image"><img src="' + mimiType + '"/></div>';
      } else {
        dataType = "string";
        editType = "textedit";
      }

      if (item.columnName === primaryKey) {
        const newCol = {
          field: item.columnName,
          headerText: item.columnName,
          width: 150,
          isPrimaryKey: true,
          textAlign: "Left",
          type: dataType,
          allowEditing: false,
          dataSize: size ? size : 25
        };
        newColumnList[i + 2] = newCol;
      } else {
        const newCol = {
          field: item.columnName,
          headerText: item.columnName,
          width: 150,
          isPrimaryKey: false,
          textAlign: "Left",
          type: dataType,
          editType: editType,
          allowEditing: allowEditing,
          template: template,
          dataSize: size ? size : 25
        };
        newColumnList[i + 2] = newCol;
      }
    });
    return newColumnList;
  } else {
    const firstRow = table.data.result[0];
    var i = 0;
    for (var key in firstRow) {
      if (key === primaryKey) {
        const newCol = {
          field: key,
          headerText: key,
          width: 150,
          isPrimaryKey: true,
          textAlign: "Left",
          type: "string",
          allowEditing: false,
          dataSize: 25
        };
        newColumnList[i + 2] = newCol;
      } else {
        const newCol = {
          field: key,
          headerText: key,
          width: 150,
          isPrimaryKey: false,
          textAlign: "Left",
          type: "string",
          allowEditing: true,
          dataSize: 25
        };
        newColumnList[i + 2] = newCol;
      }
      i++;
    }
    return newColumnList;
  }
};
function pickRandomProperty(obj) {
  var result;
  var count = 0;
  for (var prop in obj)
    if (Math.random() < 1 / ++count)
      result = prop;
  return result;
}

function materialColor() {
  // colors from https://github.com/egoist/color-lib/blob/master/color.json
  var colors = {
    "red": {
      "50": "#ffebee",
      "100": "#ffcdd2",
      "200": "#ef9a9a",
      "300": "#e57373",
      "400": "#ef5350",
      "500": "#f44336",
      "600": "#e53935",
      "700": "#d32f2f",
      "800": "#c62828",
      "900": "#b71c1c",
      "hex": "#f44336",
      "a100": "#ff8a80",
      "a200": "#ff5252",
      "a400": "#ff1744",
      "a700": "#d50000"
    },
    "pink": {
      "50": "#fce4ec",
      "100": "#f8bbd0",
      "200": "#f48fb1",
      "300": "#f06292",
      "400": "#ec407a",
      "500": "#e91e63",
      "600": "#d81b60",
      "700": "#c2185b",
      "800": "#ad1457",
      "900": "#880e4f",
      "hex": "#e91e63",
      "a100": "#ff80ab",
      "a200": "#ff4081",
      "a400": "#f50057",
      "a700": "#c51162"
    },
    "purple": {
      "50": "#f3e5f5",
      "100": "#e1bee7",
      "200": "#ce93d8",
      "300": "#ba68c8",
      "400": "#ab47bc",
      "500": "#9c27b0",
      "600": "#8e24aa",
      "700": "#7b1fa2",
      "800": "#6a1b9a",
      "900": "#4a148c",
      "hex": "#9c27b0",
      "a100": "#ea80fc",
      "a200": "#e040fb",
      "a400": "#d500f9",
      "a700": "#aa00ff"
    },
    // "deepPurple": {
    //   "50": "#ede7f6",
    //   "100": "#d1c4e9",
    //   "200": "#b39ddb",
    //   "300": "#9575cd",
    //   "400": "#7e57c2",
    //   "500": "#673ab7",
    //   "600": "#5e35b1",
    //   "700": "#512da8",
    //   "800": "#4527a0",
    //   "900": "#311b92",
    //   "hex": "#673ab7",
    //   "a100": "#b388ff",
    //   "a200": "#7c4dff",
    //   "a400": "#651fff",
    //   "a700": "#6200ea"
    // },
    "indigo": {
      "50": "#e8eaf6",
      "100": "#c5cae9",
      "200": "#9fa8da",
      "300": "#7986cb",
      "400": "#5c6bc0",
      "500": "#3f51b5",
      "600": "#3949ab",
      "700": "#303f9f",
      "800": "#283593",
      "900": "#1a237e",
      "hex": "#3f51b5",
      "a100": "#8c9eff",
      "a200": "#536dfe",
      "a400": "#3d5afe",
      "a700": "#304ffe"
    },
    "blue": {
      "50": "#e3f2fd",
      "100": "#bbdefb",
      "200": "#90caf9",
      "300": "#64b5f6",
      "400": "#42a5f5",
      "500": "#2196f3",
      "600": "#1e88e5",
      "700": "#1976d2",
      "800": "#1565c0",
      "900": "#0d47a1",
      "hex": "#2196f3",
      "a100": "#82b1ff",
      "a200": "#448aff",
      "a400": "#2979ff",
      "a700": "#2962ff"
    },
    "lightBlue": {
      "50": "#e1f5fe",
      "100": "#b3e5fc",
      "200": "#81d4fa",
      "300": "#4fc3f7",
      "400": "#29b6f6",
      "500": "#03a9f4",
      "600": "#039be5",
      "700": "#0288d1",
      "800": "#0277bd",
      "900": "#01579b",
      "hex": "#03a9f4",
      "a100": "#80d8ff",
      "a200": "#40c4ff",
      "a400": "#00b0ff",
      "a700": "#0091ea"
    },
    "cyan": {
      "50": "#e0f7fa",
      "100": "#b2ebf2",
      "200": "#80deea",
      "300": "#4dd0e1",
      "400": "#26c6da",
      "500": "#00bcd4",
      "600": "#00acc1",
      "700": "#0097a7",
      "800": "#00838f",
      "900": "#006064",
      "hex": "#00bcd4",
      "a100": "#84ffff",
      "a200": "#18ffff",
      "a400": "#00e5ff",
      "a700": "#00b8d4"
    },
    "teal": {
      "50": "#e0f2f1",
      "100": "#b2dfdb",
      "200": "#80cbc4",
      "300": "#4db6ac",
      "400": "#26a69a",
      "500": "#009688",
      "600": "#00897b",
      "700": "#00796b",
      "800": "#00695c",
      "900": "#004d40",
      "hex": "#009688",
      "a100": "#a7ffeb",
      "a200": "#64ffda",
      "a400": "#1de9b6",
      "a700": "#00bfa5"
    },
    "green": {
      "50": "#e8f5e9",
      "100": "#c8e6c9",
      "200": "#a5d6a7",
      "300": "#81c784",
      "400": "#66bb6a",
      "500": "#4caf50",
      "600": "#43a047",
      "700": "#388e3c",
      "800": "#2e7d32",
      "900": "#1b5e20",
      "hex": "#4caf50",
      "a100": "#b9f6ca",
      "a200": "#69f0ae",
      "a400": "#00e676",
      "a700": "#00c853"
    },
    "lightGreen": {
      "50": "#f1f8e9",
      "100": "#dcedc8",
      "200": "#c5e1a5",
      "300": "#aed581",
      "400": "#9ccc65",
      "500": "#8bc34a",
      "600": "#7cb342",
      "700": "#689f38",
      "800": "#558b2f",
      "900": "#33691e",
      "hex": "#8bc34a",
      "a100": "#ccff90",
      "a200": "#b2ff59",
      "a400": "#76ff03",
      "a700": "#64dd17"
    },
    "lime": {
      "50": "#f9fbe7",
      "100": "#f0f4c3",
      "200": "#e6ee9c",
      "300": "#dce775",
      "400": "#d4e157",
      "500": "#cddc39",
      "600": "#c0ca33",
      "700": "#afb42b",
      "800": "#9e9d24",
      "900": "#827717",
      "hex": "#cddc39",
      "a100": "#f4ff81",
      "a200": "#eeff41",
      "a400": "#c6ff00",
      "a700": "#aeea00"
    },
    "yellow": {
      "50": "#fffde7",
      "100": "#fff9c4",
      "200": "#fff59d",
      "300": "#fff176",
      "400": "#ffee58",
      "500": "#ffeb3b",
      "600": "#fdd835",
      "700": "#fbc02d",
      "800": "#f9a825",
      "900": "#f57f17",
      "hex": "#ffeb3b",
      "a100": "#ffff8d",
      "a200": "#ffff00",
      "a400": "#ffea00",
      "a700": "#ffd600"
    },
    "amber": {
      "50": "#fff8e1",
      "100": "#ffecb3",
      "200": "#ffe082",
      "300": "#ffd54f",
      "400": "#ffca28",
      "500": "#ffc107",
      "600": "#ffb300",
      "700": "#ffa000",
      "800": "#ff8f00",
      "900": "#ff6f00",
      "hex": "#ffc107",
      "a100": "#ffe57f",
      "a200": "#ffd740",
      "a400": "#ffc400",
      "a700": "#ffab00"
    },
    "orange": {
      "50": "#fff3e0",
      "100": "#ffe0b2",
      "200": "#ffcc80",
      "300": "#ffb74d",
      "400": "#ffa726",
      "500": "#ff9800",
      "600": "#fb8c00",
      "700": "#f57c00",
      "800": "#ef6c00",
      "900": "#e65100",
      "hex": "#ff9800",
      "a100": "#ffd180",
      "a200": "#ffab40",
      "a400": "#ff9100",
      "a700": "#ff6d00"
    },
    // "deepOrange": {
    //   "50": "#fbe9e7",
    //   "100": "#ffccbc",
    //   "200": "#ffab91",
    //   "300": "#ff8a65",
    //   "400": "#ff7043",
    //   "500": "#ff5722",
    //   "600": "#f4511e",
    //   "700": "#e64a19",
    //   "800": "#d84315",
    //   "900": "#bf360c",
    //   "hex": "#ff5722",
    //   "a100": "#ff9e80",
    //   "a200": "#ff6e40",
    //   "a400": "#ff3d00",
    //   "a700": "#dd2c00"
    // },
    "brown": {
      "50": "#efebe9",
      "100": "#d7ccc8",
      "200": "#bcaaa4",
      "300": "#a1887f",
      "400": "#8d6e63",
      "500": "#795548",
      "600": "#6d4c41",
      "700": "#5d4037",
      "800": "#4e342e",
      "900": "#3e2723",
      "hex": "#795548"
    },
    "grey": {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#eeeeee",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      "hex": "#9e9e9e"
    },
    "blueGrey": {
      "50": "#eceff1",
      "100": "#cfd8dc",
      "200": "#b0bec5",
      "300": "#90a4ae",
      "400": "#78909c",
      "500": "#607d8b",
      "600": "#546e7a",
      "700": "#455a64",
      "800": "#37474f",
      "900": "#263238",
      "hex": "#607d8b"
    },
    "black": {
      "hex": "#000000"
    },
    "white": {
      "hex": "#ffffff"
    }
  }
  // pick random property
  //var property = pickRandomProperty(colors);
  var colorList = colors[pickRandomProperty(colors)];
  var newColorKey = pickRandomProperty(colorList);
  var newColor = colorList[newColorKey];
  return newColor;
}

export var getColor = () => {
  return materialColor()
}
