import router from "../../router";
import Api from "../../app.settings.network";
import store from "../../store";
import AppSettings from "../../app.settings";
import { encriptPass, decriptPass, timeSince } from "../services";
const apiUrl = AppSettings.API_ENDPOINT;

const state = {
  userJobs: [],
  students: [],
  tables: [],
  tables2: [],
  relationship: [],
  table: { tableName: "", settings: {}, primaryKey: "", rows: [], columns: [] },
  connection: {},
  selectedQuery: {},
  sqlQueries: [],
  tableName: ""
};
const getters = {
  getSqlQueries(state) {
    let req;
    try {
      const sqlQueries = JSON.parse(
        decriptPass(localStorage.getItem("sqlQueries"))
      );
      state.sqlQueries = sqlQueries;
      req = state.sqlQueries;
      req.forEach(function (item, index) {
        let timeSinceSaved = timeSince(item.date);
        req[index].timeSince = timeSinceSaved;
      });
      return req;
    } catch (ex) {
      return req;
    }
  },
  getSelectedQuery(state) {
    return state.selectedQuery;
  },
  getConnection(state) {
    return state.connection;
  },
  getTableName(state) {
    //alert("inside store.." + state.tableName)
    return state.tableName;
  },
  getTable(state) {
    return state.table;
  },
  getTables(state) {
    const req = state.tables;
    return req;
  },
  getTables2(state) {
    const req = state.tables2;
    return req;
  },
  getEdges(state) {
    const req = state.relationship;
    //alert("GETTERS: " + JSON.stringify(req))
    return req;
  },
  getYear: () => {
    const d = new Date();
    const y = d.getUTCFullYear();
    return y;
  },
  getMonth() {
    const d = new Date();
    const m = d.getUTCMonth(); // 0 - 11
    return m;
  }
};
const mutations = {
  setSqlQueries(state, sqlQuery) {
    let sqlQueries = state.sqlQueries;
    sqlQueries.push(sqlQuery)
    state.sqlQueries = sqlQueries;
    localStorage.setItem("sqlQueries", encriptPass(JSON.stringify(state.sqlQueries)));
  },
  loadSqlQueries(state) {
    try {
      const query = localStorage.getItem("sqlQueries")
      const sqlQueries = JSON.parse(
        decriptPass(query)
      );
      state.sqlQueries = sqlQueries;
    } catch (error) {
      state.sqlQueries = [];
    }
  },
  setSelectedQuery(state, payload) {
    state.selectedQuery = payload;
  },
  setConnection: (state, payload) => {
    state.connection = payload;
    state.tableName = payload.tableName;
    //alert(router.currentRoute.name)
    //   if (router.currentRoute.name !== "SelTable"){
    //     router.push("SelTable");
    //}
  },
  setTable: (state, payload) => {
    state.table = payload;
  },
  setTables: (state, payload) => {
    state.tables = payload;
  },
  setTables2: (state, payload) => {
    state.tables2 = payload;
  },
  setRelationship: (state, payload) => {
    state.relationship = payload;
  },
  deleteTable: (state, payload) => {
    let req = state.tables;
    req.forEach(function (item, index) {
      if (item.tableName == payload.tableName) {
        req.splice(index, 1);
      }
    });
    //alert(JSON.stringify(req))
    state.tables = req;
  },
  changeTableName: (state, payload) => {
    let req = state.tables;
    req.forEach(function (item) {
      if (item.tableName == payload.tableName) {
        item.tableName = payload.newTableName;
      }
    });
    //alert(JSON.stringify(req))
    state.tables = req;
  },
  setStudents: (state, students) => {
    alert(JSON.stringify(students));
    state.students = students;
  },
  setRequestList(state, payload) {
    state.requestList = payload;
  }
};
const actions = {
  getUserProfile: commit => {
    const userName = localStorage.getItem("userName");
    // alert(userName)
    try {
      Api()
        .get(apiUrl + "api/getSurveyor/" + userName)
        .then(response => {
          // alert (JSON.stringify (response.data));
          commit("setUserProfile", response.data);
          router.replace("/");
          store.commit("setProgressStatus", false);
          // store.commit('setAlert', 'user records loaded successfully')
        })
        .catch(function (ex) {
          router.replace("/login");
          store.commit("setProgressStatus", false);
          if (ex.response === undefined) {
            alert(
              "The server is temporarily unavailble, please try again later"
            );
          } else {
            alert(JSON.stringify(ex.response));
          }
        });
    } catch (error) {
      alert(JSON.stringify(error));
    }
  },
  update: (state, payload) => {
    try {
      Api()
        .put(apiUrl + "api/Table/Update/" + payload.path, payload)
        .then(response => {
          store.commit("setProgressStatus", false);
          alert(
            "Record with " +
            payload.options.primaryKey +
            " = " +
            response.data.updated +
            " was updated successfully"
          );
        })
        .catch(function (ex) {
          alert(JSON.stringify(ex.response.data));
          state.progressStatus = false;
        });
    } catch (error) {
      alert(JSON.stringify(error));
    }
  },
  GetStudents: (state, commit) => {
    // alert(apiUrl + ' getting students')
    Api()
      .get(apiUrl + "api/students")
      .then(response => {
        alert(JSON.stringify(response.data));
        state.students = response.data;
        commit("setStudents", response.data);
        // store.commit('setProgressStatus', false)
      })
      .catch(function (ex) {
        alert(JSON.stringify(ex.response));
      });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
